import * as yup from 'yup';

export const schema = yup.object({
  first_name: yup.string().required({
    message: 'First name is required field',
  }),
  last_name: yup.string().required({
    message: 'Last name is required field',
  }),
  email: yup
    .string()
    .email({
      message: 'Value must be a valid email',
    })
    .required({
      message: 'Email is required field',
    }),
  phone: yup.string().required({
    message: 'Phone is required field',
  }),
});
