import React, { useMemo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelectList } from '../hooks';
import { SelectWrapper } from '../components';

export const SelectList = ({
  defaultValue,
  multi,
  name,
  options,
  className,
  onChange,
  onBlur,
  disabled,
  placeholder,
  label,
  additionalLabel,
  error,
  touched,
  id,
}) => {
  const { onSelectBlur, onSelectChange } = useSelectList({ name, multi, onChange, onBlur });

  const memoizedClassName = useMemo(
    () =>
      cn('select-list', className, {
        'is-invalid': touched && !!error,
      }),
    [className, error, touched],
  );
  return (
    <SelectWrapper additionalLabel={additionalLabel} label={label} error={error} touched={touched}>
      <Select
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={onSelectChange}
        onBlur={onSelectBlur}
        defaultValue={defaultValue}
        isMulti={multi}
        name={name}
        options={options}
        className={memoizedClassName}
        id={id}
      />
    </SelectWrapper>
  );
};

SelectList.propTypes = {
  defaultValue: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  additionalLabel: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

SelectList.defaultProps = {
  defaultValue: {},
  options: [{}],
  multi: false,
  disabled: false,
  touched: false,
  className: '',
  placeholder: '',
  additionalLabel: '',
  error: '',
  label: '',
  id: '',
};
