import { useCreateFormikForm, usePaymentFormSubmit } from 'app/utils/hooks';
import { registerCandidateRequest } from 'app/requestHelpers';
import { STORAGE_ITEMS } from 'app/constants';
import { schema } from '../rules';

export const useCandidates = () => {
  const { errors: registerErrors, pending, onSubmit } = usePaymentFormSubmit(
    registerCandidateRequest,
    STORAGE_ITEMS.REGISTERED_CANDIDATE,
  );

  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'register-candidate',
    validationSchema: schema,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      location_id: undefined,
      bank_id: undefined,
      experience_year_id: undefined,
      job_title_id: undefined,
      fluentLanguages: [],
      cv: '',
      cover_letter: '',
    },
    onSubmit,
  });

  return {
    handleSubmit,
    createFieldProps,
    registerErrors,
    pending,
  };
};
