import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Button, FormComponents, Loader, TextComponents } from 'app/components';
import { ROUTES } from 'app/constants';
import { useResetPassword } from './hooks';

export const ResetPassword = () => {
  const { pending, createFieldProps, handleSubmit, submitErrors, isChanged } = useResetPassword();

  return (
    <div className="reset-password">
      {pending ? (
        <Loader fullSize />
      ) : (
        <Row className="justify-content-center">
          {isChanged ? (
            <>
              <TextComponents.Label description="Your password was changed. Please use your new password to log in" />
              <div className="flex-container justify-content-center button-group">
                <Button asLink variant="primary" text="Log in" href={ROUTES.SIGN_IN} />
              </div>
            </>
          ) : (
            <Col xs={10} md={8} lg={6}>
              <Form noValidate onSubmit={handleSubmit}>
                <FormComponents.FormInput
                  label="New password"
                  type="password"
                  required
                  {...createFieldProps('password')}
                />
                <FormComponents.FormInput
                  label="Repeat password"
                  type="password"
                  required
                  {...createFieldProps('repeat')}
                />
                <FormComponents.FormErrors errors={submitErrors} />
                <div className="flex-container justify-content-center button-group">
                  <Button pending={pending} type="submit" variant="primary" text="Send" />
                </div>
              </Form>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
