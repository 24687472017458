import React from 'react';
import { Container } from 'react-bootstrap';
import { Button, Layout, TextComponents } from 'app/components';
import { ROUTES } from '../../constants';
import './style.scss';

export const Page404 = () => {
  return (
    <Layout>
      <Container className="page-404">
        <TextComponents.Title
          text="Ooops... Page not found"
          className="flex-container align-items-center page-404__title"
          type="secondary"
          align="left"
        >
          <span className="page-404__icon icon-404" />
        </TextComponents.Title>
        <div className="button-group page-404__button-group flex-container justify-content-center">
          <Button
            variant="primary"
            asLink
            externalLink
            href={ROUTES.LANDING_PAGE}
            text="Go to home page"
          />
        </div>
      </Container>
    </Layout>
  );
};
