import { useEffect, useState, useMemo } from 'react';
import { getIsLg, getIsMd, getIsSm, getIsXs } from 'app/utils/helpers';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isLg = useMemo(() => getIsLg(windowWidth), [windowWidth]);
  const isMd = useMemo(() => getIsMd(windowWidth), [windowWidth]);
  const isSm = useMemo(() => getIsSm(windowWidth), [windowWidth]);
  const isXs = useMemo(() => getIsXs(windowWidth), [windowWidth]);
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return {
    windowWidth,
    isLg,
    isMd,
    isSm,
    isXs,
  };
};
