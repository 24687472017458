import React from 'react';
import PropTypes from 'prop-types';
import { Layout, PaymentInfoWrapper } from 'app/components';
import { STORAGE_ITEMS } from 'app/constants';

export const BookingPaymentInfo = ({ failure }) => {
  return (
    <Layout>
      <PaymentInfoWrapper
        failure={failure}
        localStorageName={STORAGE_ITEMS.BOOKING_MODEL}
        fields={[
          { label: 'Booked date', field: 'booked_date' },
          { label: 'First name', field: 'first_name' },
          { label: 'Last name', field: 'last_name' },
          { label: 'Email', field: 'email' },
          { label: 'Phone', field: 'phone' },
          { label: 'CV', field: 'cv', file: true },
          { label: 'Cover letter', field: 'cover_letter', file: true },
        ]}
      />
    </Layout>
  );
};

BookingPaymentInfo.propTypes = {
  failure: PropTypes.bool,
};

BookingPaymentInfo.defaultProps = {
  failure: false,
};
