import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getIsAuth } from 'app/utils/helpers';
import { ROUTES } from 'app/constants';

export const PrivateRoute = ({ component: Component, exact, path, ...props }) => {
  const isAuth = getIsAuth();
  return (
    <>
      <Route exact={exact} path={path}>
        {isAuth ? <Component {...props} /> : <Redirect to={ROUTES.BOOKING} />}
      </Route>
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
};
