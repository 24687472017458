import React, { useMemo, useState, useLayoutEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Nav } from 'react-bootstrap';
import cn from 'classnames';
import { Button } from 'app/components';
import { ROUTES, STORAGE_ITEMS } from 'app/constants';
import { getIsAuth } from 'app/utils/helpers';
import { useWindowWidth } from 'app/utils/hooks';
import { Link } from '../link';
import './style.scss';

export const MainNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const matchUrl = useRouteMatch();
  const isAuth = getIsAuth();
  const history = useHistory();
  const onLogout = () => {
    localStorage.removeItem(STORAGE_ITEMS.AUTHORIZATION);
    history.push(ROUTES.BOOKING);
  };
  const { isLg } = useWindowWidth();

  const className = useMemo(
    () =>
      cn({
        'main-navigation-mobile': isLg,
        'main-navigation-mobile--open': isLg && isOpen,
      }),
    [isLg, isOpen],
  );

  useLayoutEffect(() => {
    if (!isLg) {
      setIsOpen(false);
    }
  }, [isLg]);

  const onToggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={className}>
        <Nav className="justify-content-end main-navigation" activeKey={matchUrl.path}>
          <Nav.Item>
            <Nav.Link href={ROUTES.LANDING_PAGE}>Home</Nav.Link>
          </Nav.Item>
          <Link href={ROUTES.BOOKING} text="Career Consulting" />
          <Link href={ROUTES.WEALTH_MANAGEMENT} text="Wealth management database" />
          <Link isHidden={!isAuth} href={ROUTES.MY_ACCOUNT} text="My account" />
          <Link isHidden={isAuth} href={ROUTES.SIGN_IN} text="Sign in" />
          {isAuth && (
            <Nav.Item>
              <Nav.Link as={Button} onClick={onLogout} text="Logout" variant="default">
                Logout
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>
      {isLg && (
        <Button
          onClick={onToggleMenu}
          className={isOpen ? 'main-navigation__close-button' : 'main-navigation__open-button'}
          variant="secondary"
        >
          <span />
          <span />
          <span />
        </Button>
      )}
    </>
  );
};
