import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { whereEq, filter, head, compose, equals } from 'ramda';
import { Container } from 'react-bootstrap';
import cn from 'classnames';
import { Tab } from './components';
import './style.scss';

export const Tabs = ({ items, activeId, vertical, className, size }) => {
  const activeItem = useMemo(() => compose(head, filter(whereEq({ id: activeId })))(items), [
    activeId,
    items,
  ]);

  const memoizedClassName = useMemo(
    () =>
      cn('tabs', className, `tabs--${size}`, {
        'tabs-vertical': vertical,
      }),
    [className, vertical, size],
  );

  const memoizedHeaderClassName = useMemo(
    () =>
      cn(
        `tabs__header flex-container align-items-center justify-content--${
          vertical ? 'start' : 'center'
        }`,
      ),
    [vertical],
  );

  return (
    <Container>
      <div className={memoizedClassName}>
        <div className="tabs__wrapper">
          <div className={memoizedHeaderClassName}>
            {items.map(({ header, onClick, id }) => (
              <Tab key={id} onClick={onClick} isActive={equals(id, activeId)} header={header} />
            ))}
          </div>
          <div className="tabs__body">{activeItem.body}</div>
        </div>
      </div>
    </Container>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      body: PropTypes.node,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  activeId: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

Tabs.defaultProps = {
  vertical: false,
  className: '',
  size: 'lg',
};
