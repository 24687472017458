import { useEffect, useState } from 'react';
import { getCandidatesRequest } from 'app/requestHelpers';
import { useCreateFormikForm, useWindowWidth } from 'app/utils/hooks';
import { schema } from '../rules';

export const useCandidatesSearch = () => {
  const [pending, setPending] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const { isSm } = useWindowWidth();
  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'candidates-search',
    validationSchema: schema,
    onChange: () => {
      handleSubmit();
    },
    initialValues: {
      location_id: [],
      bank_id: [],
      experience_year_id: [],
      job_title_id: [],
      fluentLanguages: [],
    },
    onSubmit: async value => {
      if (!pending) {
        setPending(true);
        try {
          const {
            data: { data },
          } = await getCandidatesRequest(value);
          if (data) {
            setCandidates(data);
          }
          setPending(false);
        } catch (e) {
          console.error(e);
          setPending(false);
        }
      }
    },
  });

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return {
    createFieldProps,
    pending,
    candidates,
    isSm,
  };
};
