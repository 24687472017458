import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { TextComponents, FormComponents, Button } from 'app/components';
import { useBooking } from './hooks';

export const BookingSecondStep = () => {
  const {
    onClickPrev,
    handleSubmit,
    createFieldProps,
    pending,
    bookingErrors,
    checkedTimeFrame,
  } = useBooking();

  return (
    <>
      <Button
        className="come-back-link"
        type="button"
        variant="secondary"
        onClick={onClickPrev}
        text="Prev"
      />
      <TextComponents.Label name="Step 2: " description="Fill in the following information" />
      <Row className="justify-content-center">
        <Col xs={10} md={8} lg={6}>
          <TextComponents.Label
            name="Selected date: "
            description={checkedTimeFrame}
            align="left"
          />
          <Form noValidate onSubmit={handleSubmit}>
            <FormComponents.FormInput
              label="First name"
              type="text"
              required
              {...createFieldProps('first_name')}
            />
            <FormComponents.FormInput
              label="Last name"
              type="text"
              required
              {...createFieldProps('last_name')}
            />
            <FormComponents.FormInput
              label="Email"
              required
              type="email"
              {...createFieldProps('email')}
            />
            <FormComponents.PhoneInput label="Phone" {...createFieldProps('phone')} />
            <FormComponents.FileInput
              accept=".pdf"
              additionalLabel="Only pdf format is available"
              label="Attach CV"
              required
              {...createFieldProps('cv')}
            />
            <FormComponents.FileInput
              accept=".pdf"
              additionalLabel="Only pdf format is available"
              label="Attach cover letter"
              {...createFieldProps('cover_letter')}
            />
            <FormComponents.FileInput
              label="Attach supporting documents"
              multiple
              {...createFieldProps('files')}
            />
            <FormComponents.FormInput
              label="Additional info"
              type="text"
              textarea
              {...createFieldProps('comment')}
            />
            <FormComponents.FormErrors errors={bookingErrors} />
            <FormComponents.PrivacyPolicyLabel by="booking" />
            <div className="flex-container justify-content-center button-group">
              <Button pending={pending} type="submit" variant="primary" text="Book and pay" />
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
