import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const Tab = ({ isActive, onClick, header }) => {
  const memoizedClassName = useMemo(
    () =>
      cn('tabs__header-item', {
        'tabs__header-item--active': isActive,
      }),
    [isActive],
  );

  return (
    <div
      tabIndex="0"
      role="tab"
      onKeyDown={onClick}
      onClick={onClick}
      className={memoizedClassName}
    >
      {header}
    </div>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
