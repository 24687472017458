import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { TextComponents } from 'app/components';
import { ROUTES } from 'app/constants';
import './style.scss';

export const PrivacyPolicyLabel = ({ by }) => (
  <TextComponents.Label
    className="privacy-policy-label"
    align="left"
    renderDescription={() => (
      <span>
        {`By ${by}, you agree to the processing of your personal data by Trust Search as
        described in the `}
        <NavLink to={ROUTES.PRIVACY_POLICY}>Privacy Policy</NavLink>
      </span>
    )}
  />
);

PrivacyPolicyLabel.propTypes = {
  by: PropTypes.string.isRequired,
};
