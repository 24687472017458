import React, { useState, useMemo } from 'react';
import { Switch, Route } from 'react-router';
import { Container } from 'react-bootstrap';
import { Button, Layout, Tabs, TextComponents } from 'app/components';
import {
  WealthManagementCandidates,
  // WealthManagementRecruiters,
  MyAccountSubscription,
  CandidatesSearch,
} from 'app/containers';
import { ProfileContext } from 'app/contexts';
import { ROUTES, ROLES } from 'app/constants';
import { getIsAuth, getIsUserWithRole } from 'app/utils/helpers';
import { useCurrentUser } from 'app/utils/hooks';
import { equals, propOr } from 'ramda';

export const WealthManagementPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { pending, currentUser } = useCurrentUser();

  const isAuth = getIsAuth();

  const isFiltersHidden = useMemo(
    () =>
      currentUser
        ? getIsUserWithRole(ROLES.CANDIDATE, currentUser) ||
          equals(propOr('', 'has_subscription', currentUser), 0)
        : false,
    [currentUser],
  );

  return (
    <Layout>
      <Route exact path={ROUTES.WEALTH_MANAGEMENT}>
        <TextComponents.Title text="Wealth Management Database" type="primary" />
      </Route>
      {!isAuth ? (
        <Switch>
          <Route exact path={ROUTES.WEALTH_MANAGEMENT}>
            <Container>
              <p className="text-default">
                Our executive search business the longest and the most successful line of business
                that has put us on the map of Investment banking, Capital markets and wealth
                management as a trusted advisor for many bulge bracket financial institutions and
                asset managers.
              </p>
            </Container>
            <Tabs
              size="md"
              activeId={activeTab}
              items={[
                {
                  id: 0,
                  header: 'For сandidates',
                  body: (
                    <div className="flex-container flex-column align-items-center">
                      <div className="text-default">
                        <p>Looking for a job?</p>
                        <p>Join the Wealth Management Database</p>
                      </div>
                      <div className="button-group">
                        <Button
                          text="Join"
                          variant="primary"
                          asLink
                          href={ROUTES.WEALTH_MANAGEMENT_CANDIDATES}
                        />
                      </div>
                    </div>
                  ),
                  onClick: () => setActiveTab(0),
                },
                {
                  id: 1,
                  header: 'For recruiters',
                  body: (
                    <div className="flex-container flex-column align-items-center">
                      <div className="text-default">
                        <p>Looking for a perfect employee?</p>
                        <p>Get access to Wealth Management Database</p>
                      </div>
                      <div className="button-group">
                        <Button
                          text="Coming soon"
                          variant="primary"
                          disabled
                          // asLink
                          // href={ROUTES.WEALTH_MANAGEMENT_RECRUITER}
                        />
                      </div>
                    </div>
                  ),
                  onClick: () => setActiveTab(1),
                },
              ]}
            />
          </Route>
          <Route exact path={ROUTES.WEALTH_MANAGEMENT_CANDIDATES}>
            <WealthManagementCandidates />
          </Route>
          {/* <Route exact path={ROUTES.WEALTH_MANAGEMENT_RECRUITER}> */}
          {/*  <WealthManagementRecruiters /> */}
          {/* </Route> */}
        </Switch>
      ) : (
        currentUser &&
        !pending &&
        (isFiltersHidden ? (
          <MyAccountSubscription />
        ) : (
          <ProfileContext.Provider value={currentUser}>
            <CandidatesSearch />
          </ProfileContext.Provider>
        ))
      )}
    </Layout>
  );
};
