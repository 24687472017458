import { useCallback } from 'react';
import { map, propOr } from 'ramda';

const getValue = propOr('', 'value');

export const useSelectList = ({ name, multi, onChange, onBlur }) => {
  const onSelectChange = useCallback(
    val =>
      onChange({
        target: {
          name,
          value: multi ? map(getValue, val || []) : getValue(val),
        },
      }),
    [name, multi, onChange],
  );

  const onSelectBlur = useCallback(
    () =>
      onBlur({
        target: {
          name,
        },
      }),
    [name, onBlur],
  );

  return {
    onSelectChange,
    onSelectBlur,
  };
};
