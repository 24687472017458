import { useState, useEffect } from 'react';
import { map, prop, uniq, partition, compose, filter, isEmpty } from 'ramda';
import { uploadFileRequest } from 'app/requestHelpers';
import { getListIdFromObjList, getUniqErrorMessagesFromList, notEquals } from 'app/utils/helpers';

export const useUploadFile = ({ onChange, name, multiple, value }) => {
  const [fileModels, setFileModels] = useState([]);
  const [fileUploadErrors, setFileUploadErrors] = useState([]);

  const setFieldValue = val =>
    onChange({
      target: {
        name,
        value: val,
      },
    });

  useEffect(() => {
    if (!isEmpty(value)) {
      setFileModels(multiple ? value : [value]);
    }
    // eslint-disable-next-line
  }, []);

  const onDeleteFile = e => {
    setFileUploadErrors([]);
    if (multiple) {
      const { fileId } = e.currentTarget.dataset;
      const filteredFiles = filter(
        compose(notEquals(parseInt(fileId, 10)), prop('id')),
        fileModels,
      );
      const filesId = getListIdFromObjList(filteredFiles);
      setFileModels(filteredFiles);
      return setFieldValue(filesId);
    }
    setFileModels([]);
    return setFieldValue('');
  };

  const onUploadFile = e => {
    const requests = Array.prototype.map.call(e.currentTarget.files, file => {
      const formData = new FormData();
      formData.append('file', file);
      return uploadFileRequest(formData);
    });
    Promise.all(requests)
      .then(response => {
        const [files, errors] = compose(partition(prop('id')), map(prop('data')))(response);
        const filteredErrors = getUniqErrorMessagesFromList(errors);
        const mergedFiles = uniq([...fileModels, ...files]);
        const filesId = getListIdFromObjList(mergedFiles);
        setFileUploadErrors(filteredErrors);
        setFileModels(mergedFiles);
        setFieldValue(multiple ? filesId : filesId[0]);
      })
      .catch(error => {
        console.error(error);
        setFileUploadErrors([error.message]);
      });
  };
  return {
    onUploadFile,
    onDeleteFile,
    fileModels,
    fileUploadErrors,
  };
};
