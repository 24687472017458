import { map, propOr } from 'ramda';

export const mapOptionsList = map(({ id, title }) => ({ value: id, label: title }));

export const getOptionsListRequest = (method, limit = 100) => async (title = '') => {
  try {
    const { data } = await method({ title, limit, offset: 0 });
    return data ? mapOptionsList(propOr([], 'data', data)) : [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
