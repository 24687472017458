import React from 'react';
import PropTypes from 'prop-types';
import { FormComponents } from 'app/components';
import {
  getBanksListRequest,
  getExperienceYearsListRequest,
  getFluentLanguagesListRequest,
  getJobTitlesListRequest,
  getLocationsListRequest,
} from 'app/requestHelpers';

export const MyAccountFormFields = ({ createFieldProps, mainInfo }) => {
  return (
    <>
      {mainInfo ? (
        <>
          <FormComponents.FormInput
            label="First name"
            type="text"
            required
            {...createFieldProps('first_name')}
          />
          <FormComponents.FormInput
            label="Last name"
            type="text"
            required
            {...createFieldProps('last_name')}
          />
          <FormComponents.FormInput
            label="Email"
            required
            type="email"
            {...createFieldProps('email')}
          />
        </>
      ) : (
        <>
          <FormComponents.PhoneInput label="Phone" {...createFieldProps('phone')} />
          <FormComponents.AsyncSelectList
            label="Location"
            asyncLoadOptions={getLocationsListRequest}
            defaultOptions
            {...createFieldProps('location_id')}
          />
          <FormComponents.AsyncSelectList
            label="Bank"
            asyncLoadOptions={getBanksListRequest}
            defaultOptions
            {...createFieldProps('bank_id')}
          />
          <FormComponents.AsyncSelectList
            label="Job title"
            asyncLoadOptions={getJobTitlesListRequest}
            defaultOptions
            {...createFieldProps('job_title_id')}
          />
          <FormComponents.AsyncSelectList
            label="Years of relevant experience"
            asyncLoadOptions={getExperienceYearsListRequest}
            defaultOptions
            {...createFieldProps('experience_year_id')}
          />
          <FormComponents.AsyncSelectList
            label="Fluent language"
            asyncLoadOptions={getFluentLanguagesListRequest}
            defaultOptions
            multi
            {...createFieldProps('fluentLanguages')}
          />
          <FormComponents.FileInput
            accept=".pdf"
            additionalLabel="Only pdf format is available"
            label="Attach CV"
            required
            {...createFieldProps('cv')}
          />
          <FormComponents.FileInput
            accept=".pdf"
            additionalLabel="Only pdf format is available"
            label="Attach cover letter"
            {...createFieldProps('cover_letter')}
          />
        </>
      )}
    </>
  );
};

MyAccountFormFields.propTypes = {
  createFieldProps: PropTypes.func.isRequired,
  mainInfo: PropTypes.bool,
};

MyAccountFormFields.defaultProps = {
  mainInfo: false,
};
