import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  BookingPage,
  BookingPaymentInfo,
  MyAccount,
  Page404,
  WealthManagementPage,
  WealthManagementPaymentInfo,
  SignIn,
  ForgotPasswordPage,
  ResetPasswordPage,
  PrivacyPolicyPage,
} from 'app/pages';
import { ROUTES } from 'app/constants';
import { PrivateRoute } from './privateRoute';

export const AppRoutes = () => {
  const location = useLocation();
  const fixUrl = () =>
    location.pathname.slice(-1) !== '/' ? <Redirect to={`${location.pathname}/`} /> : <></>;
  return (
    <Switch>
      <Route exact strict path="/:url" render={fixUrl} />

      <Route exact path={ROUTES.BOOKING_PAYMENT_SUCCESS}>
        <BookingPaymentInfo />
      </Route>

      <Route exact path={ROUTES.BOOKING_PAYMENT_FAILURE}>
        <BookingPaymentInfo failure />
      </Route>

      <Route exact path={ROUTES.CANDIDATES_PAYMENT_SUCCESS}>
        <WealthManagementPaymentInfo />
      </Route>

      <Route exact path={ROUTES.CANDIDATES_PAYMENT_FAILURE}>
        <WealthManagementPaymentInfo failure />
      </Route>

      <Route exact path={ROUTES.RECRUITER_PAYMENT_SUCCESS}>
        <WealthManagementPaymentInfo recruiters />
      </Route>

      <Route exact path={ROUTES.RECRUITER_PAYMENT_FAILURE}>
        <WealthManagementPaymentInfo recruiters failure />
      </Route>

      <Route exact path={ROUTES.HOME}>
        <Redirect to={ROUTES.BOOKING} />
      </Route>

      <Route exact path={ROUTES.BOOKING}>
        <BookingPage />
      </Route>

      <Route path={ROUTES.WEALTH_MANAGEMENT}>
        <WealthManagementPage />
      </Route>

      <Route exact path={ROUTES.SIGN_IN}>
        <SignIn />
      </Route>

      <Route exact path={ROUTES.FORGOT_PASSWORD}>
        <ForgotPasswordPage />
      </Route>

      <Route exact path={ROUTES.RESET_PASSWORD}>
        <ResetPasswordPage />
      </Route>

      <Route exact path={ROUTES.PRIVACY_POLICY}>
        <PrivacyPolicyPage />
      </Route>

      <PrivateRoute exact path={ROUTES.MY_ACCOUNT} component={MyAccount} />

      <Route>
        <Page404 />
      </Route>
    </Switch>
  );
};
