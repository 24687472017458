import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { Spinner } from 'react-bootstrap';
import { filter, find, compose, prop, includes, __, propEq, isEmpty } from 'ramda';
import cn from 'classnames';
import { getOptionsListRequest } from 'app/utils/helpers';
import { useSelectList } from '../hooks';
import { SelectWrapper } from '../components';

export const AsyncSelectList = ({
  multi,
  name,
  className,
  onChange,
  onBlur,
  cacheOptions,
  defaultOptions,
  asyncLoadOptions,
  disabled,
  placeholder,
  label,
  additionalLabel,
  error,
  touched,
  id,
  value,
  defaultValue,
}) => {
  const [initValue, setInitValue] = useState(multi ? !isEmpty(value) : !!value);
  useEffect(() => {
    if (initValue && isEmpty(defaultValue)) {
      const getInitValue = async () => {
        try {
          const lists = await getOptionsListRequest(asyncLoadOptions, 1000)();
          if (multi) {
            const initValues = filter(compose(includes(__, value), prop('value')), lists);
            return setInitValue(initValues);
          }
          const val = find(propEq('value', value), lists);
          return setInitValue(val);
        } catch (e) {
          console.error(e);
          return setInitValue(null);
        }
      };
      getInitValue();
    } else {
      setInitValue(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const { onSelectBlur, onSelectChange } = useSelectList({ name, multi, onChange, onBlur });

  const memoizedClassName = useMemo(
    () =>
      cn('select-list select-list--async', className, {
        'is-invalid': touched && !!error,
      }),
    [className, error, touched],
  );
  return (
    <SelectWrapper additionalLabel={additionalLabel} label={label} error={error} touched={touched}>
      {initValue !== true ? (
        <AsyncSelect
          isMulti={multi}
          placeholder={placeholder}
          isDisabled={disabled}
          name={name}
          onChange={onSelectChange}
          onBlur={onSelectBlur}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          loadOptions={getOptionsListRequest(asyncLoadOptions)}
          className={memoizedClassName}
          id={id}
          defaultValue={initValue}
        />
      ) : (
        <div className="flex-container justify-content-center">
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        </div>
      )}
    </SelectWrapper>
  );
};

AsyncSelectList.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})])),
  ]),
  defaultValue: PropTypes.shape({}),
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  additionalLabel: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  asyncLoadOptions: PropTypes.func.isRequired,
};

AsyncSelectList.defaultProps = {
  defaultValue: {},
  value: null,
  multi: false,
  defaultOptions: false,
  cacheOptions: false,
  disabled: false,
  touched: false,
  className: '',
  placeholder: '',
  additionalLabel: '',
  error: '',
  label: '',
  id: '',
};
