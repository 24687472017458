import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.scss';

export const ColorDescription = ({ className, description, color }) => {
  const memoizedClassName = useMemo(
    () => cn('color-info flex-container align-items-center', className),
    [className],
  );
  return (
    <div className={memoizedClassName}>
      <div style={{ backgroundColor: color }} className="color-info__color" />
      <div className="color-info__description">{` - ${description}`}</div>
    </div>
  );
};

ColorDescription.propTypes = {
  color: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ColorDescription.defaultProps = {
  className: '',
};
