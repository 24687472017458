import React from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Form } from 'react-bootstrap';
import {
  ModalWindow,
  Loader,
  FormComponents,
  Button,
  TextComponents,
  ColorDescription,
} from 'app/components';
import { getTimeFromDate } from 'app/utils/helpers';
import { useCalendar } from './hooks';
import './style.scss';

export const Calendar = ({ timeFrames = {}, availableDays = [] }) => {
  const {
    onDayClick,
    onCloseModal,
    isModalOpen,
    selectedDate,
    availableTimeFrames,
    onChoose,
    onCancel,
    checkedTimeFrame,
    onChangeTimeFrame,
  } = useCalendar(timeFrames, availableDays);

  return (
    <>
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin]}
        defaultView="dayGridMonth"
        dateClick={onDayClick}
        events={availableDays}
        firstDay={1}
        height="auto"
      />
      <ColorDescription color="#fcf8e3" description="Today" />
      <ColorDescription color="rgba(143, 223, 130, 0.3)" description="Available day" />
      <ModalWindow
        className="modal-time-frames"
        centered
        size="lg"
        title="Choose an available time for a consultation"
        onHide={onCloseModal}
        onEscapeKeyDown={onCloseModal}
        isOpen={isModalOpen}
      >
        <div className="modal-time-frames__body">
          <TextComponents.Label
            className="modal-time-frames__selected-date"
            name="Date: "
            description={selectedDate}
            align="left"
          />
          {availableTimeFrames.length ? (
            <Form>
              <Form.Group>
                {availableTimeFrames.map(time => (
                  <FormComponents.FormCheck
                    key={time}
                    type="radio"
                    value={time}
                    label={getTimeFromDate(time)}
                    name="consulting-available-time"
                    onChange={onChangeTimeFrame}
                    touched
                    variant="primary"
                  />
                ))}
              </Form.Group>
              <div className="button-group">
                <Button
                  onClick={onChoose}
                  disabled={!checkedTimeFrame}
                  type="button"
                  variant="primary"
                  text="Choose"
                />
                <Button onClick={onCancel} type="button" variant="secondary" text="Cancel" />
              </div>
            </Form>
          ) : (
            <Loader fullSize />
          )}
        </div>
      </ModalWindow>
    </>
  );
};

Calendar.propTypes = {
  timeFrames: PropTypes.shape({}).isRequired,
  availableDays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
