import { createRequestHelper } from './instance';

export const forgotPasswordRequest = createRequestHelper('POST', '/forgot-password');

export const forgotPasswordCheckCodeRequest = createRequestHelper(
  'POST',
  '/forgot-password/check-code',
);

export const changePasswordRequest = createRequestHelper(
  'POST',
  '/forgot-password/change-password',
);
