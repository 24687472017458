import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export const DownloadFile = ({ fileId, salt }) => {
  return (
    <a
      className="download-file"
      rel="noopener noreferrer"
      target="_blank"
      href={`${process.env.REACT_APP_API_URL}/api/files/${fileId}?salt=${salt}`}
    >
      <span className="download-file__icon icon-file" />
    </a>
  );
};

DownloadFile.propTypes = {
  fileId: PropTypes.number.isRequired,
  salt: PropTypes.string.isRequired,
};
