import React from 'react';
import { cond, T } from 'ramda';
import { Container } from 'react-bootstrap';
import { Layout, TextComponents, Loader } from 'app/components';
import { MyAccountCandidate, MyAccountRecruiter } from 'app/containers';
import { useCurrentUser } from 'app/utils/hooks';
import { getIsAdmin, getIsUserWithRole } from 'app/utils/helpers';
import { ROLES } from 'app/constants';
import { AdminBlock } from './components';

export const MyAccount = () => {
  const { currentUser } = useCurrentUser();
  const renderedMyAccount = cond([
    [getIsAdmin, () => <AdminBlock user={currentUser} />],
    [getIsUserWithRole(ROLES.CANDIDATE), () => <MyAccountCandidate />],
    [getIsUserWithRole(ROLES.RECRUITER), () => <MyAccountRecruiter />],
    [T, () => <Loader fullSize />],
  ])(currentUser);

  return (
    <Layout>
      <Container className="my-account">
        <TextComponents.Title text="My account" type="primary" />
        <div className="my-account__main-info">{renderedMyAccount}</div>
      </Container>
    </Layout>
  );
};
