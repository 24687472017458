import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Button, FormComponents, Loader, TextComponents } from 'app/components';
import { useForgotPassword } from './hooks';

export const ForgotPassword = () => {
  const { pending, email, handleSubmit, createFieldProps, submitErrors } = useForgotPassword();
  return (
    <div className="forgot-password">
      {pending ? (
        <Loader fullSize />
      ) : (
        <Row className="justify-content-center">
          {email ? (
            <>
              <TextComponents.Label align="left" asSuccess name="Please, check your email" />
              <TextComponents.Label
                align="left"
                description={`We sent an email to ${email}. Click the link in the email to reset your password`}
              />
            </>
          ) : (
            <Col xs={10} md={8} lg={6}>
              <Form noValidate onSubmit={handleSubmit}>
                <FormComponents.FormInput
                  label="We will send recovery link to your email"
                  type="email"
                  required
                  {...createFieldProps('email')}
                />
                <FormComponents.FormErrors errors={submitErrors} />
                <div className="flex-container justify-content-center button-group">
                  <Button pending={pending} type="submit" variant="primary" text="Send" />
                </div>
              </Form>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
