import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import { equals } from 'ramda';
import { Button } from 'app/components';
import './style.scss';

const isPasswordEq = equals('password');

export const FormInput = ({
  onChange,
  onBlur,
  value,
  label,
  placeholder,
  error,
  type,
  name,
  additionalLabel,
  touched,
  id,
  required,
  textarea,
  rows,
  autoComplete,
}) => {
  const [inputType, setInputType] = useState(type);
  const isTypePassword = useMemo(() => isPasswordEq(type), [type]);
  const onTogglePassword = useCallback(
    () => setInputType(isPasswordEq(inputType) ? 'text' : 'password'),
    [inputType],
  );
  const togglePasswordClassname = useMemo(
    () =>
      cn(`toggle-password-type toggle-password-type--${isPasswordEq(inputType) ? 'hide' : 'show'}`),
    [inputType],
  );
  const inputClassName = useMemo(
    () =>
      cn('form-input', {
        'form-input-password': isTypePassword,
      }),
    [isTypePassword],
  );
  const autocomplete = useMemo(() => (autoComplete ? 'on' : 'off'), [autoComplete]);

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="field-wrapper">
        <Form.Control
          className={inputClassName}
          placeholder={placeholder}
          isInvalid={touched && !!error}
          id={id}
          value={value}
          name={name}
          onChange={onChange}
          type={inputType}
          required={required}
          onBlur={onBlur}
          as={textarea ? 'textarea' : 'input'}
          rows={rows}
          autoComplete={autocomplete}
        />
        {isTypePassword && (
          <Button
            className={togglePasswordClassname}
            type="button"
            variant="secondary"
            onClick={onTogglePassword}
          >
            <span className="icon icon-eye" />
          </Button>
        )}
        {additionalLabel && <Form.Control.Feedback>{additionalLabel}</Form.Control.Feedback>}
        {error && touched && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
      </div>
    </Form.Group>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  additionalLabel: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.bool,
  error: PropTypes.string,
  rows: PropTypes.string,
  required: PropTypes.bool,
  textarea: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

FormInput.defaultProps = {
  autoComplete: '',
  label: '',
  placeholder: '',
  id: '',
  additionalLabel: '',
  error: '',
  rows: '3',
  touched: false,
  required: false,
  textarea: false,
  onBlur: () => {},
};
