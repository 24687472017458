import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import cn from 'classnames';
import './style.scss';

export const FormCheck = ({ onChange, value, label, error, type, name, touched, id, variant }) => {
  const memoizedClassName = useMemo(
    () => cn('form-check form-check-default', `form-check-${type} form-check-${variant}`),
    [type, variant],
  );

  return (
    <Form.Check className={memoizedClassName}>
      <Form.Label className="form-check__label">
        <Form.Check.Input
          className="form-check__input"
          type={type}
          isValid={touched && !error}
          id={id}
          value={value}
          name={name}
          onChange={onChange}
        />
        <div className="form-check__body">{label || value}</div>
      </Form.Label>
    </Form.Check>
  );
};

FormCheck.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  error: PropTypes.string,
  variant: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Object)])
    .isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FormCheck.defaultProps = {
  id: '',
  error: '',
  variant: 'default',
  touched: false,
};
