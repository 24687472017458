import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './style.scss';

export const SelectWrapper = ({ label, additionalLabel, error, touched, children }) => {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      {children}
      {additionalLabel && <Form.Control.Feedback>{additionalLabel}</Form.Control.Feedback>}
      {error && touched && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

SelectWrapper.propTypes = {
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
  additionalLabel: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

SelectWrapper.defaultProps = {
  touched: false,
  children: '',
  additionalLabel: '',
  error: '',
  label: '',
};
