import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .email({
      message: 'Value must be a valid email',
    })
    .required({
      message: 'Email is required field',
    }),
});
