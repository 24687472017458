import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Container } from 'react-bootstrap';
import './style.scss';

export const Label = ({
  name,
  className,
  description,
  align,
  asError,
  renderDescription,
  asSuccess,
}) => {
  const memoizedClassName = useMemo(
    () =>
      cn('text-label text-label-default', className, {
        [`text-label-${align}`]: align,
        'text-label--error': asError,
        'text-label--success': asSuccess,
      }),
    [asError, align, className, asSuccess],
  );

  return (
    <Container className="text-label-container">
      <div className={memoizedClassName}>
        <span className="text-label__name">
          <b>{name}</b>
        </span>
        {description && <span className="text-label__description">{description}</span>}
        {renderDescription && renderDescription()}
      </div>
    </Container>
  );
};

Label.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.string,
  asError: PropTypes.bool,
  asSuccess: PropTypes.bool,
  renderDescription: PropTypes.func,
};

Label.defaultProps = {
  className: '',
  name: '',
  description: '',
  align: '',
  asError: false,
  asSuccess: false,
  renderDescription: () => {},
};
