import React, { useState } from 'react';
import { Tabs } from 'app/components';
import { MyAccountSubscription } from 'app/containers';
import { useWindowWidth } from 'app/utils/hooks';
import { MyAccountForm } from './components';

export const MyAccountCandidate = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { isSm } = useWindowWidth();
  return (
    <Tabs
      vertical={!isSm}
      activeId={activeTab}
      items={[
        {
          id: 0,
          header: 'Main info',
          body: <MyAccountForm mainInfo />,
          onClick: () => setActiveTab(0),
        },
        {
          id: 1,
          header: 'Job application',
          body: <MyAccountForm />,
          onClick: () => setActiveTab(1),
        },
        {
          id: 2,
          header: 'Subscription',
          body: <MyAccountSubscription />,
          onClick: () => setActiveTab(2),
        },
      ]}
    />
  );
};
