import React from 'react';
import { Container } from 'react-bootstrap';
import { Layout, TextComponents } from 'app/components';
import { ResetPassword } from 'app/containers';

export const ResetPasswordPage = () => {
  return (
    <Layout>
      <Container>
        <TextComponents.Title text="Reset password" type="primary" />
        <ResetPassword />
      </Container>
    </Layout>
  );
};
