import { useState } from 'react';
import { values as Rvalues } from 'ramda';
import { useCreateFormikForm } from 'app/utils/hooks';
import { forgotPasswordRequest } from 'app/requestHelpers';
import { schema } from '../rules';

export const useForgotPassword = () => {
  const [submitErrors, setSubmitErrors] = useState([]);
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState('');

  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'forgot-password',
    validationSchema: schema,
    initialValues: {
      email: '',
    },
    onSubmit: async formValues => {
      if (!pending) {
        setPending(true);
        setSubmitErrors([]);
        try {
          const {
            data: { success, errors: requestErrors },
          } = await forgotPasswordRequest(formValues);
          if (success) {
            setEmail(formValues.email);
          }
          if (requestErrors) {
            const mappedErrors = Rvalues(requestErrors);
            setSubmitErrors(mappedErrors);
          }
          setPending(false);
        } catch (error) {
          setPending(false);
          setSubmitErrors([error.message]);
          console.error(error);
        }
      }
    },
  });

  return {
    pending,
    email,
    handleSubmit,
    createFieldProps,
    submitErrors,
  };
};
