import { createRequestHelper } from './instance';

export const getProfileRequest = createRequestHelper('GET', '/profile');

export const updateProfileRequest = createRequestHelper('PUT', '/profile');

export const updateProfileSubscriptionRequest = createRequestHelper(
  'GET',
  '/profile/get-subscription-url',
);

export const deleteProfileRequest = createRequestHelper('DELETE', '/profile');
