import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Container } from 'react-bootstrap';
import { TextComponents, Button } from 'app/components';
import { ROUTES } from 'app/constants';
import './style.scss';
import { __, pathOr, propOr } from 'ramda';

const createModelLabel = (name, description) =>
  description ? (
    <TextComponents.Label align="left" name={`${name}: `} description={description} />
  ) : (
    <></>
  );

export const PaymentInfoWrapper = ({ className, localStorageName, failure, fields = [] }) => {
  const [model, setModel] = useState(null);

  const memoizedClassName = useMemo(
    () =>
      cn('payment-container', className, {
        'payment-container--error': failure,
      }),
    [className, failure],
  );

  useEffect(() => {
    setModel(JSON.parse(localStorage.getItem(localStorageName) || null));
  }, [localStorageName]);

  const getPropFromModel = propOr('', __, model);
  const getFileNameFromModel = field => pathOr('', [field, 'original'], model);

  return (
    <Container className={memoizedClassName}>
      <div className="payment-container__title-wrapper">
        <TextComponents.Title
          text={failure ? 'Your payment is failure' : 'Your payment is successful'}
          className="flex-container align-items-center"
          type="secondary"
          align="left"
        >
          <span className={`payment-container__icon icon-${failure ? 'error' : 'success'}`} />
        </TextComponents.Title>
        {!failure && (
          <Container>
            <TextComponents.Label align="left" asSuccess name="Please, check your email" />
          </Container>
        )}
      </div>
      <Container>
        {!failure && model && (
          <div className="payment-info__body">
            {fields.map(({ label, field, file }) =>
              createModelLabel(label, file ? getFileNameFromModel(field) : getPropFromModel(field)),
            )}
          </div>
        )}
      </Container>
      <div className="button-group payment-container__button-group flex-container justify-content-center">
        <Button variant="primary" asLink href={ROUTES.HOME} text="Go to home page" />
      </div>
    </Container>
  );
};

PaymentInfoWrapper.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  localStorageName: PropTypes.string,
  failure: PropTypes.bool,
};

PaymentInfoWrapper.defaultProps = {
  fields: [{}],
  failure: false,
  className: '',
  localStorageName: '',
};

// @TODO: Need refactoring for the component
