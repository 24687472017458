import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { deleteProfileRequest } from 'app/requestHelpers';
import { ROUTES, STORAGE_ITEMS } from 'app/constants';

export const useDeleteAccount = pending => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const onDelete = async () => {
    if (!pending) {
      try {
        const {
          data: { success },
        } = await deleteProfileRequest();
        if (success) {
          localStorage.removeItem(STORAGE_ITEMS.AUTHORIZATION);
          history.push(ROUTES.HOME);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onOpenModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const onCloseModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return {
    onOpenModal,
    onCloseModal,
    isModalOpen,
    onDelete,
  };
};
