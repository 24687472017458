import { useEffect, useState } from 'react';
import { currentUserDataRequest } from 'app/requestHelpers';
import { getAuthToken } from 'app/utils/helpers';
import { STORAGE_ITEMS } from 'app/constants';

export const useCurrentUser = update => {
  const [pending, setPending] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const authToken = getAuthToken();
  useEffect(() => {
    const fetchUser = async () => {
      if (authToken) {
        try {
          const {
            data: { user, salt, errors },
          } = await currentUserDataRequest();
          if (user) {
            setCurrentUser({ ...user, salt });
            setPending(false);
          }
          if (errors) {
            localStorage.removeItem(STORAGE_ITEMS.AUTHORIZATION);
          }
        } catch (e) {
          console.error(e);
          localStorage.removeItem(STORAGE_ITEMS.AUTHORIZATION);
        }
      }
    };
    fetchUser();
  }, [authToken, update]);

  return {
    currentUser,
    pending,
  };
};
