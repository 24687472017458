import * as yup from 'yup';

export const schema = yup.object({
  first_name: yup.string().required({
    message: 'First name is required field',
  }),
  last_name: yup.string().required({
    message: 'Last name is required field',
  }),
  email: yup
    .string()
    .email({
      message: 'Value must be a valid email',
    })
    .required({
      message: 'Email is required field',
    }),
  phone: yup.string().required({
    message: 'Phone is required field',
  }),
  location_id: yup.number().required({
    message: 'Location is required field',
  }),
  bank_id: yup.number().required({
    message: 'Bank is required field',
  }),
  experience_year_id: yup.number().required({
    message: 'Years of relevant experience is required field',
  }),
  job_title_id: yup.number().required({
    message: 'Job title is required field',
  }),
  fluentLanguages: yup
    .array()
    .test('len', { message: 'Fluent languages is required field' }, arr => arr.length)
    .required({ message: 'Fluent languages is required field' }),
  cv: yup.string().required({
    message: 'Please attach CV file',
  }),
  cover_letter: yup.string(),
});
