import { useState, useCallback } from 'react';
import { __, compose, map, propOr, values, assoc, mergeAll } from 'ramda';
import { updateProfileRequest } from 'app/requestHelpers';
import { useCreateFormikForm, useProfile } from 'app/utils/hooks';

export const useUpdateAccount = (fields, schema) => {
  const [pending, setPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [accountErrors, setAccountErrors] = useState([]);
  const { profile, pending: getProfilePending, setProfile } = useProfile();

  const getPropFromUser = propOr('', __, profile);
  const initialValues = compose(
    mergeAll,
    map(field => assoc(field, getPropFromUser(field), {})),
  )(fields);

  const onChange = useCallback(() => {
    if (successMessage) {
      setSuccessMessage('');
    }
  }, [successMessage, setSuccessMessage]);

  const { handleSubmit, createFieldProps, dirty, resetForm } = useCreateFormikForm({
    formName: 'my-account',
    validationSchema: schema,
    initialValues,
    onChange,
    onSubmit: async formValues => {
      if (!pending) {
        setPending(true);
        setSuccessMessage('');
        try {
          const {
            data: { user, errors: requestErrors },
          } = await updateProfileRequest({
            ...profile,
            ...formValues,
          });
          resetForm(user);
          setProfile(user);
          if (user) {
            setSuccessMessage('Your profile has been successfully updated');
            setAccountErrors([]);
          }
          if (requestErrors) {
            const mappedErrors = compose(map(propOr('', 'message')), values)(requestErrors);
            setAccountErrors(mappedErrors);
          }
          setPending(false);
        } catch (e) {
          console.error(e);
          setAccountErrors([e.message]);
        }
      }
    },
  });

  return {
    handleSubmit,
    createFieldProps,
    dirty,
    accountErrors,
    pending,
    profile,
    initialValues,
    getProfilePending,
    successMessage,
  };
};
