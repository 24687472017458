import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import logo from 'app/assets/images/logo.png';
import { ROUTES } from 'app/constants';
import { MainNavigation } from '../mainNavigation';
import './style.scss';

export const Header = () => {
  return (
    <header className="header page-header">
      <Container>
        <Row className="align-content-center">
          <Col xs={6} md={3}>
            <div className="header__logo">
              <Nav.Item>
                <Nav.Link href={ROUTES.LANDING_PAGE}>
                  <img src={logo} alt="Trust search" />
                </Nav.Link>
              </Nav.Item>
            </div>
          </Col>
          <Col xs={6} md={9} className="flex-container align-items-center justify-content-end">
            <MainNavigation />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

Header.propTypes = {};
