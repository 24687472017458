export const ROUTES = {
  LANDING_PAGE: 'https://trustsearch.london/',
  HOME: '/',
  BOOKING: '/booking',
  BOOKING_PAYMENT_SUCCESS: '/booking/payment-success',
  BOOKING_PAYMENT_FAILURE: '/booking/payment-failure',
  WEALTH_MANAGEMENT: '/wealth-management',
  WEALTH_MANAGEMENT_CANDIDATES: '/wealth-management/candidates',
  WEALTH_MANAGEMENT_RECRUITER: '/wealth-management/recruiters',
  CANDIDATES_PAYMENT_SUCCESS: '/wealth-management/candidates/payment-success',
  RECRUITER_PAYMENT_SUCCESS: '/wealth-management/recruiters/payment-success',
  CANDIDATES_PAYMENT_FAILURE: '/wealth-management/candidates/payment-failure',
  RECRUITER_PAYMENT_FAILURE: '/wealth-management/recruiters/payment-failure',
  MY_ACCOUNT: '/my-account',
  SIGN_IN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:code',
  PRIVACY_POLICY: '/privacy-policy',
};
