import { useState } from 'react';
import { compose, map, propOr, values as Rvalues } from 'ramda';

export const usePaymentFormSubmit = (method, localStorageName, onSuccess) => {
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSubmit = async formValues => {
    if (!pending) {
      setPending(true);
      try {
        const {
          data: { model, redirect, errors: requestErrors },
        } = await method(formValues);
        if (model && redirect) {
          if (onSuccess) {
            onSuccess();
          }
          localStorage.setItem(localStorageName, JSON.stringify(model));
          window.location.href = redirect;
        }
        if (requestErrors) {
          const mappedErrors = compose(map(propOr('', 'message')), Rvalues)(requestErrors);
          setErrors(mappedErrors);
          setPending(false);
        }
      } catch (error) {
        setPending(false);
        setErrors([error.message]);
        console.error(error);
      }
    }
  };

  return {
    onSubmit,
    pending,
    errors,
  };
};
