import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import { Button, FormComponents, Loader, TextComponents } from 'app/components';
import { DeleteAccount } from 'app/containers';
import { useUpdateAccount } from 'app/utils/hooks';
import { schema } from './rules';

export const MyAccountForm = () => {
  const {
    profile,
    handleSubmit,
    createFieldProps,
    accountErrors,
    dirty,
    pending,
    successMessage,
  } = useUpdateAccount(['first_name', 'last_name', 'email', 'phone'], schema);

  return (
    <>
      {profile ? (
        <Row>
          <Col lg={6}>
            <Form noValidate onSubmit={handleSubmit}>
              <FormComponents.FormInput
                label="First name"
                type="text"
                required
                {...createFieldProps('first_name')}
              />
              <FormComponents.FormInput
                label="Last name"
                type="text"
                required
                {...createFieldProps('last_name')}
              />
              <FormComponents.FormInput
                label="Email"
                required
                type="email"
                {...createFieldProps('email')}
              />
              <FormComponents.PhoneInput label="Phone" {...createFieldProps('phone')} />
              <FormComponents.FormErrors errors={accountErrors} />
              {successMessage && (
                <TextComponents.Label align="left" asSuccess description={successMessage} />
              )}
              <div className="flex-container justify-content-center button-group">
                <Button
                  disabled={!dirty}
                  pending={pending}
                  type="submit"
                  variant="primary"
                  text="Save"
                />
              </div>
            </Form>
          </Col>
          <DeleteAccount pending={pending} />
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
};

MyAccountForm.propTypes = {
  mainInfo: PropTypes.bool,
};

MyAccountForm.defaultProps = {
  mainInfo: false,
};
