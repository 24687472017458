import React from 'react';
import { Container } from 'react-bootstrap';
import { TextComponents, Loader } from 'app/components';
import { CandidateItem, TableHead, Filters } from './components';
import { useCandidatesSearch } from './hooks';
import './style.scss';

export const CandidatesSearch = () => {
  const { pending, createFieldProps, candidates, isSm } = useCandidatesSearch();

  return (
    <Container className="candidates-search">
      <Filters createFieldProps={createFieldProps} />
      {pending ? (
        <Loader />
      ) : (
        <>
          {candidates.length ? (
            <div className="candidates-search__table">
              {!isSm && (
                <TableHead
                  fields={['Name', 'Job title', 'Experience', 'Language', 'Bank', 'Location']}
                />
              )}
              <div className="candidates-search__table-body">
                {candidates.map(candidate => (
                  <CandidateItem candidate={candidate} key={candidate.id} />
                ))}
              </div>
            </div>
          ) : (
            <TextComponents.Label align="center" name="No result" />
          )}
        </>
      )}
    </Container>
  );
};

CandidatesSearch.propTypes = {};

CandidatesSearch.defaultProps = {};
