import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Button } from 'app/components';
import { useUploadFile } from './hooks';
import './style.scss';

export const FileInput = ({
  onChange,
  onBlur,
  label,
  error,
  name,
  additionalLabel,
  id,
  required,
  accept,
  multiple,
  touched,
  value,
}) => {
  const { onUploadFile, onDeleteFile, fileModels, fileUploadErrors } = useUploadFile({
    onChange,
    name,
    multiple,
    value,
  });
  const isFileModels = !!fileModels.length;
  const isUploadErrors = !!fileUploadErrors.length;
  return (
    <Form.Group className="file-input">
      <Form.Label className="file-input__label">
        <div>{label}</div>
        {(multiple || !isFileModels) && (
          <>
            <Form.Control
              id={id}
              isInvalid={!!error || isUploadErrors}
              name={name}
              onChange={onUploadFile}
              onBlur={onBlur}
              required={required}
              type="file"
              accept={accept}
              multiple={multiple}
              value=""
              className="file-input__field"
            />
            <div className="file-input__label--choose-file">
              {multiple && isFileModels ? 'Add file' : 'Choose file'}
            </div>
            {(isUploadErrors || (touched && error)) && (
              <>
                {isUploadErrors ? (
                  fileUploadErrors.map(uploadError => (
                    <Form.Control.Feedback key={uploadError} type="invalid">
                      {uploadError}
                    </Form.Control.Feedback>
                  ))
                ) : (
                  <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                )}
              </>
            )}
          </>
        )}
      </Form.Label>

      <div className="flex-container flex-column file-input__file-wrapper">
        {isFileModels ? (
          fileModels.map(({ id: fileId, original }) => (
            <div className="file-input__file" key={fileId + original}>
              <div className="file-input__file-name">{original}</div>
              <Button
                className="file-input__remove-file"
                data-file-id={fileId}
                type="button"
                variant="secondary"
                onClick={onDeleteFile}
                text="X"
                title="Remove"
              />
            </div>
          ))
        ) : (
          <div>No file chosen</div>
        )}
      </div>
      {additionalLabel && <div className="form-additional-label">{additionalLabel}</div>}
    </Form.Group>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  additionalLabel: PropTypes.string,
  error: PropTypes.string,
  accept: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string,
  ]),
};

FileInput.defaultProps = {
  value: {},
  label: '',
  id: '',
  additionalLabel: '',
  error: '',
  accept: '',
  required: false,
  multiple: false,
  touched: false,
  onBlur: () => {},
};
