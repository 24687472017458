import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Button, FormComponents, Loader } from 'app/components';
import { ROUTES } from 'app/constants';
import { useAuthorization } from './hooks';

export const Authorization = () => {
  const { pending, handleSubmit, createFieldProps, loginErrors } = useAuthorization();
  return (
    <div className="auth-container">
      {pending ? (
        <Loader fullSize />
      ) : (
        <Row className="justify-content-center">
          <Col xs={10} md={8} lg={6}>
            <Form noValidate onSubmit={handleSubmit}>
              <FormComponents.FormInput
                label="Email or username"
                type="text"
                required
                {...createFieldProps('username')}
              />
              <FormComponents.FormInput
                label="Password"
                type="password"
                required
                {...createFieldProps('password')}
              />
              <FormComponents.FormErrors errors={loginErrors} />
              <Button
                variant="secondary"
                type="button"
                text="Forgot password?"
                asLink
                href={ROUTES.FORGOT_PASSWORD}
              />
              <div className="flex-container justify-content-center button-group">
                <Button type="submit" variant="primary" text="Sign in" />
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};
