import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FormComponents } from 'app/components';
import {
  getBanksListRequest,
  getExperienceYearsListRequest,
  getFluentLanguagesListRequest,
  getJobTitlesListRequest,
  getLocationsListRequest,
} from 'app/requestHelpers';
import './style.scss';

export const Filters = ({ createFieldProps }) => {
  const createAsyncSelectProps = useCallback(
    name => ({
      ...createFieldProps(name),
      defaultOptions: true,
      multi: true,
    }),
    [createFieldProps],
  );
  return (
    <div className="candidates-search__filters">
      <Form
        noValidate
        className="candidates-search__filters-form flex-container flex-wrap justify-content-center"
      >
        <FormComponents.AsyncSelectList
          label="Location"
          asyncLoadOptions={getLocationsListRequest}
          {...createAsyncSelectProps('location_id')}
        />
        <FormComponents.AsyncSelectList
          label="Bank"
          asyncLoadOptions={getBanksListRequest}
          {...createAsyncSelectProps('bank_id')}
        />
        <FormComponents.AsyncSelectList
          label="Job title"
          asyncLoadOptions={getJobTitlesListRequest}
          {...createAsyncSelectProps('job_title_id')}
        />
        <FormComponents.AsyncSelectList
          label="Years of relevant experience"
          asyncLoadOptions={getExperienceYearsListRequest}
          {...createAsyncSelectProps('experience_year_id')}
        />
        <FormComponents.AsyncSelectList
          label="Fluent language"
          asyncLoadOptions={getFluentLanguagesListRequest}
          {...createAsyncSelectProps('fluentLanguages')}
        />
      </Form>
    </div>
  );
};

Filters.propTypes = {
  createFieldProps: PropTypes.func.isRequired,
};
