import React from 'react';
import { TextComponents, Loader, Button } from 'app/components';
import { removeSecondsFromTime } from 'app/utils/helpers';
import { Calendar } from '..';
import { useBookingTimeFrames } from './hooks';
import './style.scss';

export const BookingFirstStep = () => {
  const {
    pending,
    timeFrames,
    availableDays,
    checkedTimeFrame,
    onClickNext,
  } = useBookingTimeFrames();
  return (
    <>
      <TextComponents.Title text="Career Consulting" type="primary" />
      <p className="text-default booking-first-step__price">
        <span>
          The consultation is
          <b> 300£</b>
          <b>+ 20 % VAT </b>
          for two hours
        </span>
      </p>
      {pending ? (
        <Loader fullSize />
      ) : (
        <>
          <TextComponents.Label
            name="Step 1: "
            description="Choose a available date and time for a consulting"
          />
          <Calendar timeFrames={timeFrames} availableDays={availableDays} />
          {checkedTimeFrame && (
            <TextComponents.Label
              className="booking-first-step__selected-date"
              align="center"
              name="Selected date: "
              description={removeSecondsFromTime(checkedTimeFrame)}
            />
          )}
          <div className="flex-container justify-content-center button-group">
            <Button
              disabled={!checkedTimeFrame}
              onClick={onClickNext}
              type="button"
              variant="primary"
              text="Next"
            />
          </div>
        </>
      )}
    </>
  );
};
