import { useState } from 'react';
import { __, propOr } from 'ramda';
import { updateProfileSubscriptionRequest } from 'app/requestHelpers';
import { useProfile } from 'app/utils/hooks';

export const useAccountSubscription = () => {
  const [pending, setPending] = useState(false);
  const { profile } = useProfile();
  const getPropFromUser = propOr('', __, profile);
  const expiredDate = getPropFromUser('subscription_expired_at');
  const hasSubscription = getPropFromUser('has_subscription');

  const onUpdateSubscription = async () => {
    if (!pending) {
      setPending(true);
      try {
        const {
          data: { redirect },
        } = await updateProfileSubscriptionRequest();
        if (redirect) {
          window.location.href = redirect;
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return {
    hasSubscription,
    profile,
    pending,
    onUpdateSubscription,
    expiredDate,
  };
};
