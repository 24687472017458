import { isNil, not, prop, equals, compose, props, any, curry } from 'ramda';
import { STORAGE_ITEMS, ROLES } from 'app/constants';

export const getAuthToken = () => localStorage.getItem(STORAGE_ITEMS.AUTHORIZATION);

export const getIsAuth = () => not(isNil(getAuthToken()));

export const getIsAdmin = user =>
  user ? compose(any(equals(ROLES.ADMIN)), props(['is_super_admin', 'type']))(user) : false;

export const getUserRole = prop('type');

export const getIsUserWithRole = curry((role, user) => compose(equals(role), getUserRole)(user));
