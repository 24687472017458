import React from 'react';
import { Col, Form, Row, Container } from 'react-bootstrap';
import { ROUTES } from 'app/constants';
import { Button, TextComponents, FormComponents } from 'app/components';
import {
  getExperienceYearsListRequest,
  getFluentLanguagesListRequest,
  getBanksListRequest,
  getJobTitlesListRequest,
  getLocationsListRequest,
} from 'app/requestHelpers';
import { useCandidates } from './hooks';

export const WealthManagementCandidates = () => {
  const { handleSubmit, createFieldProps, registerErrors, pending } = useCandidates();

  return (
    <Container className="wealth-management-candidates">
      <Button
        className="come-back-link"
        type="button"
        variant="secondary"
        asLink
        href={ROUTES.WEALTH_MANAGEMENT}
        text="Prev"
      />
      <p className="text-default">
        Register in the system to join Wealth Management Database only for <b> £35 + 20% VAT</b>
      </p>
      <Row className="justify-content-center">
        <Col xs={10} md={8} lg={6}>
          <Form noValidate onSubmit={handleSubmit}>
            <FormComponents.FormInput
              label="First name"
              type="text"
              required
              {...createFieldProps('first_name')}
            />
            <FormComponents.FormInput
              label="Last name"
              type="text"
              required
              {...createFieldProps('last_name')}
            />
            <FormComponents.FormInput
              label="Email"
              required
              type="email"
              {...createFieldProps('email')}
            />
            <FormComponents.PhoneInput label="Phone" {...createFieldProps('phone')} />
            <FormComponents.AsyncSelectList
              label="Location"
              asyncLoadOptions={getLocationsListRequest}
              defaultOptions
              {...createFieldProps('location_id')}
            />
            <FormComponents.AsyncSelectList
              label="Bank"
              asyncLoadOptions={getBanksListRequest}
              defaultOptions
              {...createFieldProps('bank_id')}
            />
            <FormComponents.AsyncSelectList
              label="Job title"
              asyncLoadOptions={getJobTitlesListRequest}
              defaultOptions
              {...createFieldProps('job_title_id')}
            />
            <FormComponents.AsyncSelectList
              label="Years of relevant experience"
              asyncLoadOptions={getExperienceYearsListRequest}
              defaultOptions
              {...createFieldProps('experience_year_id')}
            />
            <FormComponents.AsyncSelectList
              label="Fluent language"
              asyncLoadOptions={getFluentLanguagesListRequest}
              defaultOptions
              multi
              {...createFieldProps('fluentLanguages')}
            />
            <FormComponents.FileInput
              accept=".pdf"
              additionalLabel="Only pdf format is available"
              label="Attach CV"
              required
              {...createFieldProps('cv')}
            />
            <FormComponents.FileInput
              accept=".pdf"
              additionalLabel="Only pdf format is available"
              label="Attach cover letter"
              {...createFieldProps('cover_letter')}
            />
            <FormComponents.FormErrors errors={registerErrors} />
            <TextComponents.Label
              align="center"
              name="Fill in the form and buy a monthly subscription to finish the registration"
            />
            <FormComponents.PrivacyPolicyLabel by="registration" />
            <div className="flex-container justify-content-center button-group">
              <Button pending={pending} type="submit" variant="primary" text="Register and pay" />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
