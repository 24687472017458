import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useCurrentUser } from 'app/utils/hooks';
import { ROUTES } from 'app/constants';

export const useRedirectIfAuth = (update, onError) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(update);
  useEffect(() => {
    if (currentUser) {
      history.push(ROUTES.MY_ACCOUNT);
    } else if (onError) {
      onError();
    }
  }, [update, currentUser, history, onError]);
};
