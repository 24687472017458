import { useContext, useState } from 'react';
import { prop, propOr, propEq, find } from 'ramda';
import { CheckedTimeFrameContext } from 'app/contexts';

export const useCalendar = (timeFrames, availableDays) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const { setCheckedTimeFrame, checkedTimeFrame } = useContext(CheckedTimeFrameContext);

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => {
    setSelectedDate(null);
    setIsModalOpen(false);
  };

  const onDayClick = info => {
    const date = prop('dateStr', info);
    if (find(propEq('start', date), availableDays)) {
      onOpenModal();
      setSelectedDate(date);
    }
  };

  const onChangeTimeFrame = e => setCheckedTimeFrame(e.currentTarget.value);

  const onCancel = () => {
    setCheckedTimeFrame(null);
    onCloseModal();
  };

  const onChoose = () => {
    onCloseModal();
    window.scrollTo(0, document.body.scrollHeight);
  };

  const availableTimeFrames = propOr([], selectedDate, timeFrames);

  return {
    onDayClick,
    onCloseModal,
    isModalOpen,
    selectedDate,
    availableTimeFrames,
    onChoose,
    onCancel,
    checkedTimeFrame,
    onChangeTimeFrame,
  };
};
