import React, { useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { propOr, map, prop, join, compose } from 'ramda';
import { DownloadFile } from 'app/components';
import { ProfileContext } from 'app/contexts';
import { useWindowWidth } from 'app/utils/hooks';
import { CandidateInfoLabel } from '../candidateInfoLabel';
import './style.scss';

export const CandidateItem = ({
  candidate: {
    first_name: firstName,
    last_name: lastName,
    jobTitle,
    experienceYear,
    bank,
    location,
    email,
    phone,
    cv,
    cover_letter: coverLetter,
    fluentLanguages,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isSm } = useWindowWidth();
  const { salt } = useContext(ProfileContext);

  const getTitle = propOr('', 'title');

  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  const languages = useMemo(() => compose(join(', '), map(prop('title')))(fluentLanguages), [
    fluentLanguages,
  ]);

  const infoLabelProps = useMemo(
    () => ({
      hideName: !isSm,
      size: isSm ? 12 : 2,
    }),
    [isSm],
  );

  const additionalInfoSize = useMemo(() => (isSm ? 12 : 6), [isSm]);

  return (
    <div className="candidates-search__candidate">
      <div onClick={onToggle} onKeyDown={onToggle} role="tab" tabIndex="0">
        <span
          className={`candidates-search__candidate-open-status icon-arrow-${
            isOpen ? 'up' : 'down'
          }`}
        />
        <div className="flex-container flex-wrap candidates-search__candidate-head">
          <CandidateInfoLabel name="Name" value={`${firstName} ${lastName}`} {...infoLabelProps} />
          <CandidateInfoLabel name="Job title" value={getTitle(jobTitle)} {...infoLabelProps} />
          <CandidateInfoLabel
            name="Experience"
            value={getTitle(experienceYear)}
            {...infoLabelProps}
          />
          <CandidateInfoLabel name="Languages" value={languages} {...infoLabelProps} />
          <CandidateInfoLabel name="Bank" value={getTitle(bank)} {...infoLabelProps} />
          <CandidateInfoLabel name="Location" value={getTitle(location)} {...infoLabelProps} />
        </div>
      </div>
      {isOpen && (
        <div className="candidates-search__candidate-body">
          <div className="flex-container flex-wrap">
            <CandidateInfoLabel name="Email" value={email} size={additionalInfoSize} />
            <CandidateInfoLabel name="Phone number" value={phone} size={additionalInfoSize} />
            <CandidateInfoLabel
              name="CV"
              renderValue={() => <DownloadFile salt={salt} fileId={cv} />}
              size={additionalInfoSize}
            />
            <CandidateInfoLabel
              name="Cover letter"
              renderValue={() => <DownloadFile salt={salt} fileId={coverLetter} />}
              size={additionalInfoSize}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CandidateItem.propTypes = {
  candidate: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    jobTitle: PropTypes.shape({}),
    experienceYear: PropTypes.shape({}),
    bank: PropTypes.shape({}),
    location: PropTypes.shape({}),
    cv: PropTypes.number,
    cover_letter: PropTypes.number,
    fluentLanguages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
