import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Form, Col, Row } from 'react-bootstrap';
import { useUpdateAccount } from 'app/utils/hooks';
import { Button, FormComponents, Loader, TextComponents } from 'app/components';
import { DeleteAccount } from 'app/containers';
import { MyAccountFormFields } from '../myAccountFormFields';
import { schema } from './rules';

export const MyAccountForm = ({ mainInfo }) => {
  const {
    profile,
    handleSubmit,
    createFieldProps,
    accountErrors,
    dirty,
    pending,
    getProfilePending,
    successMessage,
  } = useUpdateAccount(
    [
      'first_name',
      'last_name',
      'email',
      'phone',
      'location_id',
      'bank_id',
      'experience_year_id',
      'job_title_id',
      'fluentLanguages',
      'cv',
      'cover_letter',
    ],
    schema,
  );

  return (
    <>
      {!getProfilePending && !isEmpty(profile) ? (
        <Row>
          <Col lg={6}>
            <Form noValidate onSubmit={handleSubmit}>
              <MyAccountFormFields createFieldProps={createFieldProps} mainInfo={mainInfo} />
              <FormComponents.FormErrors errors={accountErrors} />
              {successMessage && (
                <TextComponents.Label align="left" asSuccess description={successMessage} />
              )}
              <div className="flex-container justify-content-center button-group">
                <Button disabled={!dirty} type="submit" variant="primary" text="Save" />
              </div>
            </Form>
          </Col>
          {mainInfo && <DeleteAccount pending={pending} />}
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
};

MyAccountForm.propTypes = {
  mainInfo: PropTypes.bool,
};

MyAccountForm.defaultProps = {
  mainInfo: false,
};
