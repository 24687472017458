import { useEffect, useState } from 'react';
import { getProfileRequest } from 'app/requestHelpers';

export const useProfile = () => {
  const [pending, setPending] = useState(true);
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const {
          data: { user },
        } = await getProfileRequest();
        if (user) {
          setProfile(user);
          setPending(false);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchProfile();
  }, []);

  return {
    profile,
    setProfile,
    pending,
    setPending,
  };
};
