import React from 'react';
import { Container } from 'react-bootstrap';
import { Layout, TextComponents } from 'app/components';
import { Authorization } from 'app/containers';

export const SignIn = () => {
  return (
    <Layout>
      <Container>
        <TextComponents.Title text="Sign in" type="primary" />
        <Authorization />
      </Container>
    </Layout>
  );
};
