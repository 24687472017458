import React from 'react';
import { Container } from 'react-bootstrap';
import { Layout, TextComponents } from 'app/components';
import './style.scss';

export const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Container>
        <TextComponents.Title text="Privacy policy" type="primary" />
        <div className="privacy-policy">
          <p>
            Data protection is of a particularly high priority for the management of the
            (hereinafter referred to as “TrustSearch” or “Website”), and all types of Partner &
            Affiliate Programs. When the processing of your personal data is necessary and if
            there’s no basis for such processing, we will obtain your consent. Processing of
            personal data, including your name, address, email address, or phone number will always
            be in line with the General Data Protection Regulation (GDPR), and in accordance with
            United States data protection regulations applicable to Trust Search. Via this data
            protection declaration, we are informing the general public of the nature, scope, and
            purpose of the personal data we collect, use, and process. Furthermore, you are
            informed, by this data protection declaration, of the rights to which you are entitled.
            As the data controller and data processor, Trust Search has implemented numerous
            technical and organizational measures to ensure complete protection of your personal
            data through the Website. Since Internet-based data transmissions may have security
            gaps, absolute protection is not guaranteed. This is why, every data subject is free to
            transfer personal data to us via all available means, e.g. by email or phone support.
          </p>
          <h4>Name and Address of the controller</h4>
          <p>
            Controller for the purposes of the General Data Protection Regulation (GDPR) is:
            Trustsearch International Limited, 20-22 Wenlock Road, London, England, N1 7GU Owner
            contact email: <a href="mailto:elvira@trustsearch.london">elvira@trustsearch.london</a>
          </p>
          <h4>What types of information does Trust Search collect?</h4>
          <p>We collect certain information about visitors and users of our websites.</p>
          <ol>
            <li>
              <h5>Information that we obtain from users and clients.</h5>
              <p>
                Names, member names, email addresses, phone numbers, other contact details,
                information about your computer, your visits to and use of this website (including
                information about your browser, IP address, general location by your browser),
                responses to the surveys initiated for research purposes, payment details (like
                information of your credit card), transaction details, support queries, web
                analytics data, information that you provide when you fill in forms on our websites.
                We may use this information to respond to your requests or correspondence, to
                provide customer service (or for statistical purposes), analysis for management
                purposes in order to administer the website, improve products/services, send
                technical notices/updates, security alerts, changes in policy notifications,
                administrative messages, so we could to prevent fraud, breach of policies/terms, and
                threats or harm.
              </p>
            </li>

            <li>
              <h5>Information that we obtain from our partners.</h5>
              <p>
                Name, company name, website URL, phone number(s), address, business type, email
                address, and information from merchant processors like Stripe/Paypal, email address
                or ID etc. We use this information to work with you, confirm your identity, contact
                you, pay you and screen for risk, fraud, or other similar types of issues, to send
                you technical notices, updates, security alerts, information regarding changes to
                our policies, and support and administrative messages.
              </p>
            </li>

            <li>
              <h5>Information that we obtain from website visitors.</h5>
              <p>
                The device type and browser you are using, network connection, IP address,
                information about the cookies installed on your device, support queries, and web
                analytics data. Also, we may collect personal information submitted by you via any
                feature available on our website.
              </p>
            </li>

            <li>
              <h5>Information that we obtain from users who referred to chat support.</h5>
              <p>
                Name, email address, information about the device and browser you use, chat
                transcript, and other personal information you provide us during our chat.
              </p>
            </li>
          </ol>
          <h4>How do we collect personal information?</h4>
          <ol>
            <li>
              When you perform certain actions like register on Website, buy/order items or
              services, subscribe to our newsletter, submit feedback, enter a contest, fill out a
              survey, or send us a communication via any available option.
            </li>
            <li>
              We collect personal information automatically as you navigate through the sites or
              through the 3rd party when you use services associated with our site.
            </li>
          </ol>
          <h4>What personal information do we collect about you from Third Parties?</h4>
          Even though we’re collecting personal information directly from you, occasionally we may
          collect certain information about you from other sources.
          <ol>
            <li>
              Financial and/or transaction details from payment providers (in order to process the
              transaction).
            </li>
            <li>
              Third party services like Google, Facebook, Twitter may provide us with certain
              information about you when you link/connect/login to any third party provider. The
              amount of information may vary and can be/is controlled by that service provider or by
              you via privacy settings controls available in the account settings of that service
              provider.
            </li>
            <li>
              From Third Parties we may obtain demographic data, fraud detection information,
              combine it with the info we have about you, to confirm your identity, these can be
              identity verification services used for fraud warning purposed.
            </li>
          </ol>
          <h4>How does Trust Search use personal information?</h4>
          <ol>
            <li>
              Your information is used when we need to define your identity to ease processing of
              transactions happening on our website, for example when you purchase items from our
              catalog.
            </li>
            <li>
              Other legitimate interests may include:
              <ol type="a">
                <li>providing you with services described on any of our websites;</li>
                <li>
                  verifying your identity when you sign in to Trust Search and/or any platforms
                  related to Trust Search;
                </li>
                <li>providing support services;</li>
                <li>sharing company news and websites/services updates;</li>
                <li>
                  checking your actions on our website to identify potentially fraudulent activity
                  and to ensure compliance with the user terms that apply to the websites;
                </li>
                <li>
                  respond to your comments or queries on our websites, ask for your feedback or
                  opinion via surveys;
                </li>
                <li>
                  managing legal/operational affairs including, risk management related to content
                  and fraud matters;
                </li>
                <li>improving our products and services.</li>
                <br />
              </ol>
            </li>
            <li>
              When you give us consent:
              <ol type="a">
                <li>
                  to provide you with marketing information as for products and services which may
                  be interesting for you;
                </li>
                <li>
                  to modify/customize our services and websites, like advertising that appears on
                  our website, this involves the use of cookies to provide a personalized
                  experience.
                </li>
                <br />
              </ol>
            </li>
            <li>For multiple purposes required by the law.</li>
            <li>
              For granting court of law, law enforcement authorities, government requests or any
              other occasions that may relate to investigations.
            </li>
          </ol>
          <h4>When do we disclose your personal information?</h4>
          <b>Your personal information may be accessed by:</b>
          <ol>
            <li>
              individuals, for instance employees, or legal entities empowered by Trust Search, who
              need it to perform their tasks and duties to provide you with proper services;
            </li>
            <li>third parties who have a legitimate purpose for accessing it.</li>
          </ol>
          <br />
          <b>
            We may share your Personal Information with third parties in the following cases, when:
          </b>
          <ol>
            <li>the relevant customer has consented to the disclosure;</li>
            <li>it’s needed for support purposes and/or license validation/activation;</li>
            <li>
              subcontractors or service providers who assist us in any possible ways, subcontractors
              and/or services providers may transfer and access your personal information from other
              countries in which they have operations;
            </li>
            <li>
              it’s required by the law either to protect our rights, or someone from injury, and/or
              comply with a judicial proceeding, court order, or legal process.
            </li>
          </ol>
          <h4>Where do we transfer and/or store your personal information?</h4>
          <p>
            Trust Search is located in Great Britain. The support companies only provide an internal
            supporting role to and for the benefit of Trust Search. This is executed based on your
            consent to this Policy. To protect your information, we’re working with subcontractors
            who can maintain an acceptable standard of data security compliance.
          </p>
          <h4>How long do we keep your personal information?</h4>
          <p>
            Your private information is kept on our servers as long as it’s needed to provide you
            with our services and to be able to comply with the legal obligations. If you don’t want
            us to store your personal information, you have the right to get it erased, but in this
            case, we’ll have to close your Trust Search account. All information about deleted
            accounts is kept on our servers so we could use it for our legitimate business
            interests: to comply with the law, prevent fraud, resolve disputes, troubleshoot
            problems, assist with investigations, enforce the terms of use and/or take other actions
            permitted by law. The information we retain will be handled in accordance with this
            Privacy Policy.
          </p>
          <h4>How is your personal information kept secure?</h4>
          In order to keep your information secure, we’re applying certain organizational measures
          to protect it against an unauthorized or unlawful use and accidental loss or destruction.
          <ol>
            <li>
              We share and provide access to your information to the minimum extent necessary,
              subject to confidentiality restrictions where appropriate, and on an anonymized basis
              wherever possible.
            </li>
            <li>To store your information we’re using secure servers.</li>
            <li>
              We verify an identity of all individuals who request access to private information
              before granting them with access.
            </li>
            <li>We use SSL-certificate encryption to protect the data transfer.</li>
          </ol>
          <p>
            Make sure your information is safe and secured. We’re not responsible if you try to
            circumvent privacy settings on our websites. In case we give you the password to certain
            parts of our website you’re responsible for keeping it safe. You must not share it with
            third parties especially comments, questions area since these areas can be viewed by any
            user of our website. Due to obvious reasons, we’re not able to control users’ actions on
            our website. Which is why you need to share your information in public areas of our
            websites wisely, as we can’t guarantee that your information won’t be viewed by
            unauthorized parties. When you choose to share your information via a third party
            website, you need to check the privacy policy of that website to be aware of their
            information management/protection practices.
          </p>
          <h4>How can you access and manage your personal information?</h4>
          <p>
            All personal information that we collect about you is available in your account or in
            our servers and available for you on demand. You have the right to make all the
            necessary edits and corrections of any errors available in this data. You can receive
            your personal information in a structured, machine-readable format, erase it, or
            restrict its processing, also you can object processing of your personal data based on a
            set of our legitimate interests, such as profiling for direct marketing, and cases where
            we’ve asked for your consent to process your data. There’s a number of situations when
            this right can be limited, there’s a number of cases when we can retain your information
            after you’ve withdrawn your consent. Cases where we may require your personal
            information to be compliant with legal or contractual obligations. If you don’t provide
            us with such data we will not be able to manage relationships with you. In all other
            cases providing requested information can be optional. If it happens that you have some
            unresolved concerns you have the right to complain to the data protection authorities.
          </p>
          <h4>Marketing choices regarding your personal information</h4>
          <p>
            In case we have your consent we will send you marketing materials that will include
            information about products, services, and discounts that may be interesting for you. If
            you decide not to receive such email notifications you can easily opt-out using the
            unsubscribe option or contact us via{' '}
            <a href="mailto:elvira@trustsearch.london">elvira@trustsearch.london</a>
          </p>
          <h4>Cookies</h4>
          <p>
            Trust Search and all websites of our partners are using cookies to analyze user
            behavior, gather demographic info about our user base in general. A cookie is a small
            file that’s placed on your device when you visit any website. Using the browser settings
            of your device you can disallow the use of cookies, in case you disable those you can
            limit certain features on our website. We’re using functional and analytical cookies,
            for further information on cookies visit{' '}
            <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
          </p>
          <h4>Google Analytics</h4>
          <p>
            Trust Search is using Google Analytics, a web analytics service designed by Google, Inc.
            (“Google”). Google Analytics software uses cookies to analyze users’ behavior. All data
            (i.e. your activity within Trust Search website) is gathered by cookies and stored on
            Google servers in the United States. Google in its turn uses this data to evaluate user
            behavior in general, compile reports for site managers as well as providing data to
            services relating to website activity and internet usage. Google may transfer various
            data to third parties where required to do so by law, or where such third parties
            process the information on Google’s behalf. In no way, Google will associate your IP
            address with any other data held by Google. In case you don’t want to provide Google
            with the browsing data you can block the use of cookies via your browser settings. More
            information on Google analytics opt-out:
            <a href="https://www.google.com/intl/en/policies/privacy/">
              https://www.google.com/intl/en/policies/privacy/
            </a>
            , and{' '}
            <a href="http://www.google.com/analytics/terms/us.html">
              http://www.google.com/analytics/terms/us.html
            </a>
            . Google Analytics is explained under the following link{' '}
            <a href="https://www.google.com/analytics/">https://www.google.com/analytics/</a>.
          </p>
          <h4>Public information and third party websites</h4>
          <br />
          <h4>Testimonials</h4>
          <p>
            In addition to various endorsements, we’re displaying testimonials on our website. These
            are published only after receiving your consent, we post your testimonials specifying
            your name. If you want to delete or modify your existing testimonials feel free to
            contact us via email at{' '}
            <a href="mailto:elvira@trustsearch.london">elvira@trustsearch.london</a>.
          </p>
          <h4>Social media platforms and widgets</h4>
          <p>
            Trust Search website has social media features embedded, for example, Facebook like
            button. These features, may collect your data like IP address or place a cookie to your
            browser in order to gather statistical information about your interactions with our
            websites. Social media features are hosted both on our website and servers of a specific
            social media network. Apart from these features, we maintain profiles on major networks
            including Facebook, Twitter Pinterest, Instagram and LinkedIn. Please be advised that
            any information you submit on these networks in public areas like comments can be easily
            read, collected or stored by all users of these networks. We are not able to control
            actions of other users of these platforms, or actions of these platforms. Your activity
            with those features and/or platforms is governed by privacy policies of the companies
            that provide them.
          </p>
          <h4>Links to other websites</h4>
          <p>
            If you click on a link to a third party site, you will leave Trust Search and go to the
            site you selected. Since we can’t control activities of third parties, we are not
            accepting responsibility for any use of your personal information by those parties.
            Please review the privacy policy any other service provider from whom you request
            services.
          </p>
          <h4>Routine erasure and blocking of personal data</h4>
          <p>
            The information controller shall store the non-public data just in the amount necessary
            to attain the aim of storage, or as this is often granted by European legislators in
            laws or rules to which the controller is subject. If the storage purpose isn&apos;t
            applicable, or if a storage period prescribed by the European legislator or another
            competent instance expires, the non-public information will be blocked or erased.
          </p>
          <h4>Children’s privacy</h4>
          <p>
            Trust Search website, as well as its blog and services, are not intended to be used by
            children under 13 years old. No one under age 13 years old can provide personal
            information to or on our websites. We’re not collecting information from children under
            13 years old. If you’re under 13 years old you’re not allowed to use the website, buy
            service available on Trust Search, make social media posts or provide any personal
            information about yourself including your name, address, phone number, email. If we
            learn that you violated this term your account, as well as all information, will be
            deleted. In case you think that we may have some information provided by your child
            please contact us via email at{' '}
            <a href="mailto:elvira@trustsearch.london">elvira@trustsearch.london</a>.
          </p>
          <h4>Changes in our Privacy Policy</h4>
          <p>
            As the data controller and data processor, we reserve the right to change or modify this
            Privacy Policy at any time. In case some changes are applied, we will publish them in
            the Privacy Policy section. You will be notified via email that certain changes to our
            Policy were applied.
          </p>
          <h4>Contact information</h4>
          Depending on the context of personal information you provide, Trust Search is both data
          controller and data processor of your personal information under this policy. Trust Search
          is a processor of personal information submitted to the services or collected through the
          services on behalf of or at the direction of subscribers. If you have any questions
          regarding this Privacy Policy, including processing of your personal information, or if
          you believe your privacy rights have been violated, please contact us at{' '}
          <a href="mailto:elvira@trustsearch.london">elvira@trustsearch.london</a>. We will respond
          to all requests, inquiries or concerns within fifteen (15) days.
          <h4>The legal basis for the processing</h4>
          <p>
            Art. 6(1) GDPR serves as the legal basis for processing operations for which we obtain
            consent for a specific processing purpose.
          </p>
        </div>
      </Container>
    </Layout>
  );
};
