import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Spinner, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './style.scss';

export const Button = ({
  onClick,
  type,
  className,
  variant,
  children,
  text,
  disabled,
  pending,
  asLink,
  externalLink,
  href,
  ...props
}) => {
  const memoizedClassName = useMemo(
    () =>
      cn(
        {
          'button-default': variant === 'primary',
          'button-default--danger': variant === 'primary-danger',
          'button-secondary': variant === 'secondary',
          'button-as-link': asLink,
        },
        className,
      ),
    [className, variant, asLink],
  );
  return (
    <>
      {asLink ? (
        <>
          {externalLink ? (
            <Nav.Link className={memoizedClassName} href={href} {...props}>
              {children || text}
            </Nav.Link>
          ) : (
            <Nav.Link className={memoizedClassName} as={NavLink} to={href} {...props}>
              {children || text}
            </Nav.Link>
          )}
        </>
      ) : (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={memoizedClassName}
          {...props}
        >
          {pending ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            <>{children || text}</>
          )}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  asLink: PropTypes.bool,
  externalLink: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => {},
  className: '',
  children: '',
  text: '',
  type: 'button',
  href: '',
  disabled: false,
  pending: false,
  asLink: false,
  externalLink: false,
};
