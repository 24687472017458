import { createRequestHelper } from './instance';

export const getBanksListRequest = createRequestHelper('GET', '/banks');

export const getExperienceYearsListRequest = createRequestHelper('GET', '/experience-years');

export const getFluentLanguagesListRequest = createRequestHelper('GET', '/fluent-languages');

export const getJobTitlesListRequest = createRequestHelper('GET', '/job-titles');

export const getLocationsListRequest = createRequestHelper('GET', '/locations');
