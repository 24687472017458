import React from 'react';
import PropTypes from 'prop-types';
import { Layout, PaymentInfoWrapper } from 'app/components';

export const WealthManagementPaymentInfo = ({ failure }) => {
  return (
    <Layout>
      <PaymentInfoWrapper failure={failure} />
    </Layout>
  );
};

WealthManagementPaymentInfo.propTypes = {
  failure: PropTypes.bool,
};

WealthManagementPaymentInfo.defaultProps = {
  failure: false,
};
