import React from 'react';
import { Layout } from 'app/components';
import { BookingContainer } from 'app/containers';

export const BookingPage = () => {
  return (
    <Layout>
      <BookingContainer />
    </Layout>
  );
};
