import React from 'react';
import PropTypes from 'prop-types';
import { TextComponents } from 'app/components';

export const FormErrors = ({ errors }) => (
  <div className="form-errors">
    {errors.map(error => (
      <TextComponents.Label align="left" asError key={error} name="Error: " description={error} />
    ))}
  </div>
);

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
