import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import './style.scss';

export const TableHead = ({ fields }) => {
  return (
    <div className="candidates-search__table-head flex-container align-items-center justify-content-between">
      {fields.map(field => (
        <Col key={field} className="candidates-search__table-head-item">
          <b>{field}</b>
        </Col>
      ))}
    </div>
  );
};

TableHead.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};
