import { useCreateFormikForm, usePaymentFormSubmit } from 'app/utils/hooks';
import { bookingConsultingRequest } from 'app/requestHelpers';
import { BOOKING_STEPS, STORAGE_ITEMS } from 'app/constants';
import { useContext } from 'react';
import { CheckedTimeFrameContext } from 'app/contexts';
import { schema } from '../rules';

export const useBooking = () => {
  const { errors: bookingErrors, pending, onSubmit } = usePaymentFormSubmit(
    bookingConsultingRequest,
    STORAGE_ITEMS.BOOKING_MODEL,
  );

  const { setStep, checkedTimeFrame } = useContext(CheckedTimeFrameContext);

  const onClickPrev = () => setStep(BOOKING_STEPS.FIRST);

  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'booking',
    validationSchema: schema,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      comment: '',
      cv: '',
      cover_letter: '',
      files: [],
      booked_date: checkedTimeFrame,
    },
    onSubmit,
  });

  return {
    onClickPrev,
    handleSubmit,
    createFieldProps,
    pending,
    bookingErrors,
    checkedTimeFrame,
  };
};
