import moment from 'moment';
import { curry, slice, lastIndexOf } from 'ramda';

export const getDateFromString = curry((inputFormat, outputFormat, string) => {
  return moment(string, inputFormat).format(outputFormat);
});

export const momentUTC = date => moment(date).utc(true);

export const addHoursToUTCDate = (date, hours) => momentUTC(date).add(hours, 'hours');

export const getAvailableTimesFrames = (from, to, hours, initArr = []) => {
  return from < to
    ? getAvailableTimesFrames(addHoursToUTCDate(from, hours), to, hours, [
        ...initArr,
        getDateFromString('YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', from),
      ])
    : initArr;
};

export const removeSecondsFromTime = time => slice(0, lastIndexOf(':', time), time);

export const getTimeFromDate = getDateFromString('YYYY-MM-DD HH:mm:ss', 'HH:mm');
