import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { equals, cond, T } from 'ramda';
import cn from 'classnames';
import { Container } from 'react-bootstrap';
import './style.scss';

const getTitle = (text, type, align) =>
  cond([
    [
      equals('primary'),
      () => (
        <h2 className="title-primary">
          <span>{text}</span>
        </h2>
      ),
    ],
    [
      equals('secondary'),
      () => <h2 className={cn('title-secondary', `title-secondary-align-${align}`)}>{text}</h2>,
    ],
    [T, () => <h2>{text}</h2>],
  ])(type);

export const Title = ({ text, className, type, align, children }) => {
  const renderedTitle = useMemo(() => getTitle(text, type, align), [text, type, align]);

  return (
    <Container>
      <div className={className}>
        {renderedTitle}
        {children}
      </div>
    </Container>
  );
};

Title.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  align: PropTypes.string,
};

Title.defaultProps = {
  className: '',
  align: 'left',
  children: '',
};
