import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { TextComponents } from 'app/components';

export const CandidateInfoLabel = ({ name, value, hideName, size, renderValue }) => {
  const infoName = useMemo(() => (hideName ? '' : `${name}: `), [name, hideName]);
  const infoSize = useMemo(() => size || 'auto', [size]);
  return (
    <Col sm={infoSize} className="candidates-search__candidate-item">
      <TextComponents.Label
        align="left"
        name={infoName}
        renderDescription={renderValue}
        description={value}
      />
    </Col>
  );
};

CandidateInfoLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  hideName: PropTypes.bool,
  renderValue: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CandidateInfoLabel.defaultProps = {
  renderValue: () => {},
  hideName: false,
  size: 'auto',
  value: '',
};
