import axios from 'axios';
import { cond, equals } from 'ramda';
import { STORAGE_ITEMS } from 'app/constants';

export const api = () => {
  const token = localStorage.getItem(STORAGE_ITEMS.AUTHORIZATION);
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers,
  });
};

export const createRequestHelper = (method, url) =>
  cond([
    [equals('GET'), () => (params = {}) => api().get(url, { params })],
    [equals('POST'), () => (data, params = {}) => api().post(url, data, { params })],
    [equals('PUT'), () => (data, params = {}) => api().put(url, data, { params })],
    [equals('DELETE'), () => (params = {}) => api().delete(url, { params })],
  ])(method);
