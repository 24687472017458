import React, { useState } from 'react';
import { equals, cond } from 'ramda';
import { Container } from 'react-bootstrap';
import { CheckedTimeFrameContext } from 'app/contexts';
import { BOOKING_STEPS } from 'app/constants';
import { BookingFirstStep, BookingSecondStep } from './components';

export const BookingContainer = () => {
  const [step, setStep] = useState(BOOKING_STEPS.FIRST);
  const [checkedTimeFrame, setCheckedTimeFrame] = useState(null);
  const renderStep = cond([
    [equals(BOOKING_STEPS.FIRST), () => <BookingFirstStep />],
    [equals(BOOKING_STEPS.SECOND), () => <BookingSecondStep />],
  ]);
  return (
    <Container>
      <CheckedTimeFrameContext.Provider
        value={{
          checkedTimeFrame,
          setCheckedTimeFrame,
          setStep,
        }}
      >
        {renderStep(step)}
      </CheckedTimeFrameContext.Provider>
    </Container>
  );
};
