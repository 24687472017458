import { useState } from 'react';
import { useHistory } from 'react-router';
import { useCreateFormikForm } from 'app/utils/hooks';
import { ROUTES, STORAGE_ITEMS } from 'app/constants';
import { values as Rvalues } from 'ramda';
import { getIsAuth } from 'app/utils/helpers';
import { loginRequest } from 'app/requestHelpers';
import { useRedirectIfAuth } from './useRedirectIfAuth';
import { schema } from '../rules';

export const useAuthorization = () => {
  const isAuth = getIsAuth();
  const [loginErrors, setLoginErrors] = useState([]);
  const history = useHistory();
  const [pending, setPending] = useState(isAuth);
  const onUserNotAuth = () => setPending(false);

  useRedirectIfAuth(null, onUserNotAuth);

  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'auth',
    validationSchema: schema,
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async formValues => {
      if (!pending) {
        setPending(true);
        setLoginErrors([]);
        try {
          const {
            data: { user, token, errors: requestErrors },
          } = await loginRequest(formValues);
          if (user && token) {
            localStorage.setItem(STORAGE_ITEMS.AUTHORIZATION, token);
            history.push(ROUTES.MY_ACCOUNT);
          }
          if (requestErrors) {
            const mappedErrors = Rvalues(requestErrors);
            setLoginErrors(mappedErrors);
            setPending(false);
          }
        } catch (error) {
          setPending(false);
          setLoginErrors([error.message]);
          console.error(error);
        }
      }
    },
  });

  return {
    pending,
    handleSubmit,
    createFieldProps,
    loginErrors,
  };
};
