import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Form } from 'react-bootstrap';
import './style.scss';
import cn from 'classnames';

export const PhoneInput = ({
  onChange,
  onBlur,
  value,
  label,
  placeholder,
  className,
  error,
  name,
  additionalLabel,
  touched,
  id,
  required,
}) => {
  const memoizedClassName = useMemo(
    () =>
      cn('react-tel-input', className, {
        'is-invalid': touched && !!error,
      }),
    [className, error, touched],
  );

  const memoizedInputClassName = useMemo(
    () =>
      cn('phone-input', className, {
        'is-invalid': touched && !!error,
      }),
    [className, error, touched],
  );

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <ReactPhoneInput
        placeholder={placeholder}
        containerClass={memoizedClassName}
        inputClass={memoizedInputClassName}
        preferredCountries={['gb']}
        country="gb"
        enableSearch
        enableLongNumbers
        value={value}
        regions={['america', 'europe', 'asia', 'oceania', 'africa']}
        id={id}
        inputProps={{
          name,
          required,
          onChange,
          onBlur,
        }}
      />
      {additionalLabel && <Form.Control.Feedback>{additionalLabel}</Form.Control.Feedback>}
      {error && touched && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  additionalLabel: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

PhoneInput.defaultProps = {
  label: '',
  className: '',
  placeholder: '',
  id: '',
  additionalLabel: '',
  error: '',
  touched: false,
  required: false,
  onBlur: () => {},
};
