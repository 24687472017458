import { useCallback } from 'react';
import { useFormik } from 'formik';
import { getErrorMessage } from '../helpers';

export const useCreateFormikForm = ({
  initialValues = {},
  validationSchema = {},
  onSubmit,
  formName = '',
  onChange,
}) => {
  const {
    handleSubmit,
    handleBlur,
    values,
    handleChange,
    errors,
    touched,
    dirty,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit,
  });
  const memoizedOnChange = useCallback(
    e => {
      handleChange(e);
      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange],
  );
  const createFieldProps = useCallback(
    name => ({
      name,
      onBlur: handleBlur,
      onChange: memoizedOnChange,
      value: values[name],
      error: getErrorMessage(errors[name]),
      touched: touched[name],
      id: `${formName}-${name}`,
      autoComplete: true,
    }),
    [values, errors, handleBlur, memoizedOnChange, touched, formName],
  );

  return {
    createFieldProps,
    handleSubmit,
    dirty,
    resetForm,
  };
};
