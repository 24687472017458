import * as yup from 'yup';
import { PASS_MIN_LENGTH } from 'app/constants';

export const schema = yup.object({
  password: yup
    .string()
    .required({
      message: 'Password is required field',
    })
    .min(PASS_MIN_LENGTH, { message: `Password must be at least ${PASS_MIN_LENGTH} characters` }),
  repeat: yup
    .string()
    .required({
      message: 'Please repeat your new password',
    })
    .oneOf([yup.ref('password')], { message: 'The password does not match' }),
});
