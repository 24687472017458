import { curry } from 'ramda';
import { WINDOW_WIDTH } from 'app/constants';

export const getIsBigger = curry((a, b) => a > b);

export const getIsLg = getIsBigger(WINDOW_WIDTH.LG);

export const getIsMd = getIsBigger(WINDOW_WIDTH.MD);

export const getIsSm = getIsBigger(WINDOW_WIDTH.SM);

export const getIsXs = getIsBigger(WINDOW_WIDTH.XS);
