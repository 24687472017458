import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

export const Link = ({ text, href, isHidden }) => {
  return (
    <>
      {!isHidden && (
        <Nav.Item>
          <Nav.Link as={NavLink} to={href}>
            {text}
          </Nav.Link>
        </Nav.Item>
      )}
    </>
  );
};

Link.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  isHidden: PropTypes.bool,
};

Link.defaultProps = {
  href: '/',
  isHidden: false,
};
