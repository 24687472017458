import React from 'react';
import PropTypes from 'prop-types';
import { Button, ModalWindow, TextComponents } from 'app/components';
import { Col, Form } from 'react-bootstrap';
import { useDeleteAccount } from './hooks';

export const DeleteAccount = ({ pending }) => {
  const { onOpenModal, onCloseModal, isModalOpen, onDelete } = useDeleteAccount(pending);

  return (
    <>
      <Col lg={6}>
        <div className="flex-container justify-content-center button-group">
          <Button
            onClick={onOpenModal}
            type="button"
            variant="primary-danger"
            text="Delete my account"
          />
        </div>
      </Col>
      <ModalWindow
        className="modal-time-frames"
        centered
        size="sm"
        title="Delete account"
        onHide={onCloseModal}
        onEscapeKeyDown={onCloseModal}
        isOpen={isModalOpen}
      >
        <div>
          <TextComponents.Label name="Are you sure?" align="left" />
          <TextComponents.Label description="This action cannot be undone" align="left" />
          <Form>
            <div className="button-group">
              <Button
                onClick={onDelete}
                disabled={pending}
                type="button"
                variant="primary"
                text="Choose"
              />
              <Button onClick={onCloseModal} type="button" variant="secondary" text="Cancel" />
            </div>
          </Form>
        </div>
      </ModalWindow>
    </>
  );
};

DeleteAccount.propTypes = {
  pending: PropTypes.bool,
};

DeleteAccount.defaultProps = {
  pending: false,
};
