import React from 'react';
import { propOr, __ } from 'ramda';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { TextComponents } from 'app/components';

export const AdminBlock = ({ user }) => {
  const getPropFromUser = propOr('', __, user);
  return (
    <Row className="flex-container justify-content-center">
      <Col sm={6}>
        <TextComponents.Label
          align="left"
          name="First name: "
          description={getPropFromUser('first_name')}
        />
        <TextComponents.Label
          align="left"
          name="Last name: "
          description={getPropFromUser('last_name')}
        />
        <TextComponents.Label align="left" name="Email: " description={getPropFromUser('email')} />
        <TextComponents.Label align="left" name="Role: " description="Admin" />
      </Col>
    </Row>
  );
};

AdminBlock.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
