import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { values } from 'ramda';
import { useCreateFormikForm } from 'app/utils/hooks';
import { forgotPasswordCheckCodeRequest, changePasswordRequest } from 'app/requestHelpers';
import { ROUTES } from 'app/constants';
import { schema } from '../rules';

export const useResetPassword = () => {
  const [submitErrors, setSubmitErrors] = useState([]);
  const [pending, setPending] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const history = useHistory();

  const {
    params: { id, code },
  } = useRouteMatch();
  useEffect(() => {
    const checkIsValid = async () => {
      try {
        const {
          data: { success },
        } = await forgotPasswordCheckCodeRequest({
          id,
          code,
        });
        if (success) {
          return setPending(false);
        }
        return history.push(ROUTES.HOME);
      } catch (e) {
        console.error(e.message);
        return history.push(ROUTES.HOME);
      }
    };
    checkIsValid();
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, createFieldProps } = useCreateFormikForm({
    formName: 'reset-password',
    validationSchema: schema,
    initialValues: {
      password: '',
      repeat: '',
    },
    onSubmit: async formValues => {
      if (!pending) {
        setPending(true);
        setSubmitErrors([]);
        try {
          const {
            data: { success, errors: requestErrors },
          } = await changePasswordRequest({ ...formValues, code, id });
          setIsChanged(!!success);
          if (requestErrors) {
            const mappedErrors = values(requestErrors);
            setSubmitErrors(mappedErrors);
          }
          setPending(false);
        } catch (error) {
          setPending(false);
          setSubmitErrors([error.message]);
          console.error(error);
        }
      }
    },
  });

  return {
    pending,
    handleSubmit,
    createFieldProps,
    submitErrors,
    isChanged,
  };
};
