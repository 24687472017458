import * as yup from 'yup';
import { includes } from 'ramda';
import { Email } from 'app/utils/regEx';

export const schema = yup.object({
  username: yup
    .string()
    .required({
      message: 'Email or username is required',
    })
    .test('email', { message: 'Email is incorrect' }, val =>
      val && includes('@', val) ? Email.test(val) : val,
    ),
  password: yup.string().required({
    message: 'Password is required field',
  }),
});
