import React from 'react';
import { Button, TextComponents } from 'app/components';
import { useAccountSubscription } from './hooks';

export const MyAccountSubscription = () => {
  const {
    hasSubscription,
    profile,
    pending,
    onUpdateSubscription,
    expiredDate,
  } = useAccountSubscription();

  return (
    <div className="my-account__subscription">
      {hasSubscription ? (
        <TextComponents.Label align="center" name={`Your subscription expires on ${expiredDate}`} />
      ) : (
        <>
          <TextComponents.Label align="center" name="Your subscription is expired" />
          <div className="flex-container justify-content-center button-group">
            <Button
              pending={!profile || pending}
              onClick={onUpdateSubscription}
              type="button"
              variant="primary"
              text="Update subscription"
            />
          </div>
        </>
      )}
    </div>
  );
};
