import { useState, useEffect, useContext } from 'react';
import {
  __,
  assoc,
  compose,
  filter,
  flatten,
  map,
  mapObjIndexed,
  pick,
  includes,
  reduce,
  not,
  split,
  sort,
  keys,
} from 'ramda';
import { BOOKING_STEPS } from 'app/constants';
import { CheckedTimeFrameContext } from 'app/contexts';
import { getTimeFrames } from 'app/requestHelpers';
import { getAvailableTimesFrames, momentUTC } from 'app/utils/helpers';

export const useBookingTimeFrames = () => {
  const [timeFrames, setTimeFrames] = useState({});
  const [availableDays, setAvailableDays] = useState([]);
  const [pending, setPending] = useState(true);

  const { checkedTimeFrame, setStep } = useContext(CheckedTimeFrameContext);

  const onClickNext = () => {
    setStep(BOOKING_STEPS.SECOND);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { data = [], bookedDates = [] },
        } = await getTimeFrames();

        const availableTimeFrames = compose(
          mapObjIndexed(sort((a, b) => (a > b ? 1 : -1))),
          reduce((acc, date) => {
            const [key] = split(' ', date);
            return {
              ...acc,
              [key]: acc[key] ? [...acc[key], date] : [date],
            };
          }, {}),
          filter(compose(not, includes(__, bookedDates))),
          flatten,
          map(
            compose(
              ({ from, to }) => getAvailableTimesFrames(from, to, 2),
              mapObjIndexed(momentUTC),
              pick(['from', 'to']),
            ),
          ),
        )(data);
        const availableBookingDays = compose(
          map(assoc('start', __, { allDay: true, rendering: 'background' })),
          keys,
        )(availableTimeFrames);

        setTimeFrames(availableTimeFrames);
        setAvailableDays(availableBookingDays);
        setPending(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return {
    pending,
    checkedTimeFrame,
    timeFrames,
    availableDays,
    onClickNext,
  };
};
